/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
import { Form, Formik, FieldArray } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import FormField from "components/common/FormField";
import SoftButton from "components/SoftButton";

// Form schemas
import getValidations from "layouts/pages/business/new-business/schemas/validations";
import { formDataFields } from "layouts/pages/business/new-business/schemas/form";
import getInitialValues from "layouts/pages/business/new-business/schemas/initialValues";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum";

// Helpers
import { BusinessItemTypes } from "helpers/enums/business-item.enum";

import ButtonsSection from "layouts/pages/business/new-business/components/ButtonsSection";
import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import {useTranslation} from "react-i18next";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from "moment";
import theme from "assets/theme";
import { createTheme, ThemeProvider } from "@mui/material";
import inputBase from "assets/theme/components/form/inputBase";
import dayjs from 'dayjs';

function ProgramDetails({ activeStep, isLastStep, handleNext, handleBack, type }) {
  const { t, i18n } = useTranslation();

  const { preventMinus, preventPasteNegative, validations } = getValidations(t);
  const initial = getInitialValues(t).initial;

  const [ageCategoriesOptions, setAgeCategoriesOptions] = useState([]);
  const [sportsOptions, setSportsOptions] = useState([]);
  const [skillLevelsOptions, setSkillLevelsOptions] = useState([]);
  const [selectedTypeValue, setSelectedTypeValue] = useState(BusinessItemTypes.PERSONAL_COACH);
  const [startTime, endTime] = useState(moment());

  const { palette, functions } = theme;
  const { inputColors, dark } = palette;
  const { pxToRem, boxShadow } = functions;

  const createdTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ...inputBase.styleOverrides.root,
            "&.Mui-focused": {
              borderColor: inputColors.borderColor.focus,
              paddingLeft: pxToRem(12),
              paddingRight: pxToRem(12),
              boxShadow: boxShadow([0, 0], [0, 2], inputColors.boxShadow, 1),
              outline: 0,
            },

            '& .MuiInputBase-input::placeholder': {
              color: `${dark.main} !important`,
            },

            "& fieldset": {
              border: "none !important",
              "&.Mui-focused": {
                borderColor: inputColors.borderColor.focus,
                paddingLeft: pxToRem(12),
                paddingRight: pxToRem(12),
                boxShadow: boxShadow([0, 0], [0, 2], inputColors.boxShadow, 1),
                outline: 0,
              },
            },

            input: {
              ...inputBase.styleOverrides.input,
            },
          }
        }
      }
    },
  });

  // Form data
  const formValidation = validations[FormNames.PROGRAM_DETAILS_FORM];
  const formFields = formDataFields(t)[FormNames.PROGRAM_DETAILS_FORM];
  const initialValues = initial[FormNames.PROGRAM_DETAILS_FORM];

  const renderAdditionalData = (values, errors, touched, setFieldValue) => {
    switch (selectedTypeValue) {
      case BusinessItemTypes.SPORT_FACILITY:
        return (
          <SoftBox mb={1} mt={-1} isplay="inline-block">
            <SoftBox>
              <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('form.programDetails.workingHoursMandatory')}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" alignItems="center" mb={2}>
                <FieldArray
                  name={formFields.workingHours.name}
                  error={errors.workingHours}
                  render={arrayHelpers => (
                    <SoftBox>
                      {values.workingHours?.map((workingHoursElem, index) => (
                        <Grid container spacing={3} key={index}>
                          <Grid item xs={12} sm={5}>
                            <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {formFields.workingHours.props.day.label}
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                              menuPortalTarget={document.querySelector('body')}
                              name={`workingHours[${index}].day`}
                              defaultValue={initialValues.workingHours[index] ? initialValues.workingHours[index]?.day : formFields.workingHours.props.day.default}
                              options={formFields.workingHours.props.day.options}
                              onChange={(event) => {
                                setFieldValue(`workingHours[${index}].day`, event);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                {formFields.workingHours.props.startTime.label}
                              </SoftTypography>
                            </SoftBox>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <ThemeProvider theme={createdTheme}>
                                <TimePicker
                                  value={dayjs(values.workingHours[index].startTime)}
                                  onChange={(newTime) => {
                                    setFieldValue(`workingHours[${index}].startTime`, dayjs(newTime))
                                  }}
                                  ampm={false}
                                  renderInput={(params) => <TextField {...params}
                                  />}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                {formFields.workingHours.props.endTime.label}
                              </SoftTypography>
                            </SoftBox>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <ThemeProvider theme={createdTheme}>
                                {console.log(initialValues)}
                                <TimePicker
                                  value={dayjs(values.workingHours[index].endTime)}
                                  onChange={(newTime) => {
                                    setFieldValue(`workingHours[${index}].endTime`, dayjs(newTime))
                                  }}
                                  ampm={false}
                                  renderInput={(params) => <TextField {...params}
                                  />}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {t('general.action')}
                              </SoftTypography>
                            </SoftBox>
                            <Tooltip title={t('buttonLabels.remove')} placement="bottom" arrow>
                              <SoftBox>
                                <IconButton variant="gradient" color="error" size="medium"
                                  disabled={values.workingHours.length <= 1}
                                  onClick={() => {
                                    if (values.workingHours.length > 1) {
                                      arrayHelpers.remove(index)
                                    }
                                  }}>
                                  <DeleteIcon sx={{ fontWeight: "bold" }}>delete</DeleteIcon>
                                </IconButton>
                              </SoftBox>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ))}
                      <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
                        <Tooltip title={t('buttonLabels.add')} placement="bottom" arrow>
                          <SoftButton color="secondary" size="small"
                            onClick={() => arrayHelpers.push({
                              day: formFields.workingHours.props.day.default,
                              startTime: "",
                              endTime: ""
                            })}
                          >
                            {t('buttonLabels.plusAdd')}
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  )}
                />
              </SoftBox>
            </SoftBox>
          </SoftBox>)
      case BusinessItemTypes.GROUP_ACTIVITY:
        return (
          <SoftBox mb={1} mt={-1} isplay="inline-block">
            <SoftBox>
              <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('form.programDetails.scheduleMandatory')}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" alignItems="center" mb={2}>
                <FieldArray
                  name={formFields.schedule.name}
                  error={errors.schedule}
                  render={arrayHelpers => (
                    <SoftBox width="100%">
                      {values.schedule?.map((scheduleElem, index) => (
                        <Grid container spacing={3} key={index}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={formFields.schedule.props.description.type}
                              label={formFields.schedule.props.description.label}
                              name={`schedule[${index}].description`}
                              placeholder={formFields.schedule.props.description.placeholder}
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                {formFields.schedule.props.startTime.label}
                              </SoftTypography>
                            </SoftBox>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <ThemeProvider theme={createdTheme}>
                                <TimePicker
                                  value={dayjs(values.schedule[index].startTime)}
                                  onChange={(newTime) => {
                                    setFieldValue(`schedule[${index}].startTime`, dayjs(newTime))
                                  }}
                                  ampm={false}
                                  renderInput={(params) => <TextField {...params}
                                  />}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                {formFields.schedule.props.endTime.label}
                              </SoftTypography>
                            </SoftBox>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <ThemeProvider theme={createdTheme}>
                                <TimePicker
                                  value={dayjs(values.schedule[index].endTime)}
                                  onChange={(newTime) => {
                                    setFieldValue(`schedule[${index}].endTime`, dayjs(newTime))
                                  }}
                                  ampm={false}
                                  renderInput={(params) => <TextField {...params}
                                  />}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {t('general.action')}
                              </SoftTypography>
                            </SoftBox>
                            <Tooltip title={t('buttonLabels.remove')} placement="bottom" arrow>
                              <SoftBox>
                                <IconButton variant="gradient" color="error" size="medium"
                                  disabled={values.schedule.length <= 1}
                                  onClick={() => {
                                    if (values.schedule.length > 1) {
                                      arrayHelpers.remove(index)
                                    }
                                  }}>
                                  <DeleteIcon sx={{ fontWeight: "bold" }}>delete</DeleteIcon>
                                </IconButton>
                              </SoftBox>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ))}
                      <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
                        <Grid container spacing={3}>
                          <Grid item sm={10}></Grid>
                          <Grid item sm={2}>
                            <Tooltip title={t('buttonLabels.add')} placement="bottom" arrow>
                              <SoftButton color="secondary" size="small" style={{ marginRight: "42px" }}
                                onClick={() => arrayHelpers.push({
                                  description: "",
                                  startTime: "",
                                  endTime: ""
                                })}
                              >
                                {t('buttonLabels.plusAdd')}
                              </SoftButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  )}
                />
              </SoftBox>
            </SoftBox>
          </SoftBox>)
      default:
        break;
    }
  }

  const handleSubmit = async (values, actions) => {
    try {
      console.log("PROGRAM DETAILS HANDLE SUBMIT")
      console.log(values)

      handleNext({
        ageCategory: values.ageCategory,
        gender: values.gender,
        skillLevel: values.skillLevel,
        sport: values.sport,
        numberOfFreeTrainings: values.numberOfFreeTrainings,
        workingHours: values.workingHours,
        schedule: values.schedule,
      })
    } catch (error) {
      // TODO
      // openErrorSB(GetErrorMessage(error));
    }
  };

  const getAgeCategoriesOptions = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_AGE_CATEGORIES);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  const getSportsOptions = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_SPORTS);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  const getSkillLevelsOptions = async () => {
    try {
      const response = await CreateGetRequest(BackRequests.GET_SKILL_LEVELS);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (type) {
        setSelectedTypeValue(type);
      }
      setAgeCategoriesOptions(await getAgeCategoriesOptions());
      setSportsOptions(await getSportsOptions());
      setSkillLevelsOptions(await getSkillLevelsOptions());
    }
    loadData();

  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form id={FormNames.PROGRAM_DETAILS_FORM} autoComplete="off">
          <SoftBox>
            <SoftTypography variant="h5">{t('form.programDetails.programDetails')}</SoftTypography>
            <SoftBox mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      {formFields.ageCategory.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={formFields.ageCategory.name}
                    options={ageCategoriesOptions}
                    isMulti
                    value={values.ageCategory}
                    onChange={(event) => {
                      setFieldValue(formFields.ageCategory.name, event);
                    }}
                    error={(errors.ageCategory && touched.ageCategory) ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      {formFields.gender.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={formFields.gender.name}
                    options={formFields.gender.options}
                    value={values.gender}
                    onChange={(event) => {
                      setFieldValue(formFields.gender.name, event);
                    }}
                    error={(errors.gender && touched.gender) ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      {formFields.skillLevel.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    name={formFields.skillLevel.name}
                    options={skillLevelsOptions}
                    isMulti
                    value={values.skillLevel}
                    onChange={(event) => {
                      setFieldValue(formFields.skillLevel.name, event);
                    }}
                    error={(errors.skillLevel && touched.skillLevel) ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      {formFields.sport.label}
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    menuPortalTarget={document.querySelector('body')}
                    name={formFields.sport.name}
                    options={sportsOptions}
                    isMulti
                    value={values.sport}
                    onChange={(event) => {
                      setFieldValue(formFields.sport.name, event);
                    }}
                  />
                </Grid>
                {type != BusinessItemTypes.EVENT && (
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type={formFields.numberOfFreeTrainings.type}
                      label={formFields.numberOfFreeTrainings.label}
                      name={formFields.numberOfFreeTrainings.name}
                      value={values.numberOfFreeTrainings}
                      placeholder={formFields.numberOfFreeTrainings.placeholder}
                      onKeyPress={preventMinus}
                      onPaste={preventPasteNegative}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                )}
              </Grid>
            </SoftBox>
            <SoftBox mt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {renderAdditionalData(values, errors, touched, setFieldValue)}
                </Grid>
              </Grid>
            </SoftBox>
            <ButtonsSection activeStep={activeStep} isLastStep={isLastStep} handleBack={handleBack} isSubmitting={isSubmitting} t={t} />
          </SoftBox>
        </Form>
      )}
    </Formik>
  );
}

export default ProgramDetails;
