/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import { FieldArray } from "formik";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "components/common/FormField";
import SoftButton from "components/SoftButton";

// Form schemas
import getValidations from "layouts/pages/business/edit-business/schemas/validations";
import getFormFields from "layouts/pages/business/edit-business/schemas/form";
import { calculateDiscountedPrice } from "layouts/pages/business/business-form-helpers";
import { useTranslation } from "react-i18next";

function PricingEdit({ values, errors, touched, handleChange, setFieldValue }) {
  const { t } = useTranslation();
  const {formFields} = getFormFields(t);
  const {preventMinus, preventPasteNegative} = getValidations(t);

  return (
    <SoftBox p={3} display="grid">
      <SoftTypography variant="h5">{t('businessItem.editPage.pricing')}</SoftTypography>
      <FieldArray
        name={formFields.pricingList.name}
        error={errors.pricingList}
        render={arrayHelpers => (
          <SoftBox mt={3}>
            {values.pricingList?.map((pricingListElem, index) => (
              <Grid container spacing={3} key={index}>
                <Grid item xs={12} sm={5}>
                  <FormField
                    type={formFields.pricingList.props.description.type}
                    label={formFields.pricingList.props.description.label}
                    name={`pricingList[${index}].description`}
                    placeholder={formFields.pricingList.props.description.placeholder} />
                </Grid>
                <Grid item xs={12} sm={1.5}>
                  <FormField
                    type={formFields.pricingList.props.price.type}
                    label={formFields.pricingList.props.price.label}
                    name={`pricingList[${index}].price`}
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ min: 0 }}
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue(`pricingList[${index}].discountedPrice`, calculateDiscountedPrice(event.target.value, values.pricingList[index].discount));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={1.5}>
                  {/* <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {formFields.pricingList.props.currency.label}
                          </SoftTypography>
                        </SoftBox>
                        <SoftSelect
                          name={`pricingList[${index}].currency`}
                          defaultValue={(initialValues.pricingList && initialValues.pricingList[index]) ? initialValues.pricingList[index]?.currency : formFields.pricingList.props.currency.default}
                          options={formFields.pricingList.props.currency.options}
                          onChange={(event) => {
                            setFieldValue(`pricingList[${index}].currency`, event);
                          }}
                          disabled
                        /> */}
                  {/* [CHANGE CURRENCY TO LIST] remove below FormField and uncomment previous */}
                  <FormField
                    type="text"
                    label={formFields.pricingList.props.currency.label}
                    name={`pricingList[${index}].currency`}
                    value={values.pricingList[index].currency?.toUpperCase()}
                    error={errors.country && touched.country}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={1.5}>
                  <FormField
                    type={formFields.pricingList.props.discount.type}
                    label={formFields.pricingList.props.discount.label}
                    name={`pricingList[${index}].discount`}
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    inputProps={{ min: 5 }}
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue(`pricingList[${index}].discountedPrice`, calculateDiscountedPrice(values.pricingList[index].price, event.target.value));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={1.5}>
                  <FormField
                    type={formFields.pricingList.props.discountedPrice.type}
                    label={formFields.pricingList.props.discountedPrice.label}
                    name={`pricingList[${index}].discountedPrice`}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={1} sx={{"textAlign": "center"}}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {t('businessItem.editPage.action')}
                    </SoftTypography>
                  </SoftBox>
                  <Tooltip title={t('businessItem.editPage.removePricingListItem')} placement="bottom" arrow>
                    <SoftBox>
                      <IconButton variant="gradient" color="primary" size="medium"
                        disabled={values.pricingList.length <= 1}
                        onClick={() => {
                          if (values.pricingList.length > 1) {
                            arrayHelpers.remove(index)
                          }
                        }}>
                        <DeleteIcon sx={{ fontWeight: "bold" }}>{t('businessItem.editPage.deleteTooltip')}</DeleteIcon>
                      </IconButton>
                    </SoftBox>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
            <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
              <Tooltip title={t('businessItem.editPage.addPricingListItem')} placement="bottom" arrow>
                <SoftButton color="secondary" size="small"
                  onClick={() => arrayHelpers.push({
                    description: "",
                    price: 0,
                    currency: formFields.pricingList.props.currency.default,
                    discount: 0,
                    discountedPrice: 0
                  })}
                >
                  + {t('businessItem.editPage.addButton')}
                </SoftButton>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        )}
      />
    </SoftBox>
  );
}

export default PricingEdit;
