/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Form schemas
import { useEffect, useState } from "react";
import FileInput from "./sub-components/FileInput";
import ImageCropper from "./sub-components/ImageCropper";
import { useTranslation } from "react-i18next";

function ImageEdit({ initialImage, type, showTitle, selectImageAction, values, showMainImageError, shouldReset, setFieldValue }) {
  const { t, i18n } = useTranslation();
  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState(!initialImage ? "choose-img" : "img-chosen");
  const [imgAfterCrop, setImgAfterCrop] = useState("");
  const [aspectRatio, setAspectRatio] = useState(1/1);
  const [previewImgWidth, setPreviewImgWidth] = useState("60%");


  // Callback function when an image is selected
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
    selectImageAction(selectedImg, type, values, setFieldValue);
  }

  // Callback function when cropping is done
  const onCropDone = (imgCroppedArea) => {
    // Create a canvas element to crop the image
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    // Load selected image
    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      //Draw the cropped portion of the image onto the canvas
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      // Convert the canvas content to a data URL (JPEG format)
      const dataURL = canvasEle.toDataURL("image/jpeg");


      setImgAfterCrop(dataURL);
      setCurrentPage("img-cropped");
      selectImageAction(dataURL, type, values, setFieldValue);
    }
  }

  // Callback function when cropping is canceled
  const onCropCancel = (imgCroppedArea) => {
    if(initialImage) {
      setCurrentPage("img-cropped");
      setImage(initialImage);
      selectImageAction(initialImage, type, values, setFieldValue);
    } else {
      setCurrentPage("choose-img");
      setImage("");
      selectImageAction("", type, values, setFieldValue);
    }
  }

  const useEffectAction = () => {
    if (initialImage) {
      setImage(initialImage);
      selectImageAction(initialImage, type, values, setFieldValue);
    }
    switch (type) {
      case "main":
          setAspectRatio(1/1);
          setPreviewImgWidth("60%");
          break;
      case "cover":
          setAspectRatio(6.4/1);
          setPreviewImgWidth("100%");
          break;
      case "gallery":
          setAspectRatio(4/3);
          setPreviewImgWidth("30%");
          break;
    }
  }

  useEffect(() => {
    console.log("AAA")
    useEffectAction();
  }, []);

  useEffect(() => {
    if(initialImage){
      setImgAfterCrop(initialImage);
      setCurrentPage("img-cropped");
    }
    useEffectAction();
  }, [shouldReset]);

  return (
    <SoftBox p={showTitle ? 3 : 0} display="grid" height="100%">
      {showTitle && <SoftTypography variant="h5">{type== "main" ? t('businessItem.editPage.mainImage') : (type== "cover" ? t('businessItem.editPage.coverImage') : "")}</SoftTypography>}
      <SoftBox mt={showTitle ? 1.625 : 0} textAlign="center">
      { (imgAfterCrop || image) &&
        ( <SoftBox component="img" src={imgAfterCrop ? imgAfterCrop : image} width={previewImgWidth}/>
        )}

        {currentPage === "choose-img" ? (
          <FileInput onImageSelected={onImageSelected} showMainImageError={showMainImageError} type={type}></FileInput>
        ) : currentPage === "crop-img" ? (
          <ImageCropper image={image} onCropDone={onCropDone} onCropCancel={onCropCancel} title={showTitle && <SoftBox>{type== "main" ? t('businessItem.editPage.mainImage') : (type== "cover" ? t('businessItem.editPage.coverImage') : "")}</SoftBox>} aspectRatio={aspectRatio}/>
        ) : (
          <SoftBox textAlign="center">
            <Tooltip title={t('businessItem.editPage.removeImage')} placement="bottom" arrow>
              <SoftBox>
                <IconButton variant="gradient" color="primary" size="medium"
                  onClick={() => {
                    // Start over by choosing a new image
                    setCurrentPage("choose-img");
                    setImage("");
                    selectImageAction("", type, values, setFieldValue);
                    setImgAfterCrop("");
                  }}>
                  <DeleteIcon sx={{ fontWeight: "bold" }} fontSize="medium">{t('businessItem.editPage.deleteTooltip')}</DeleteIcon>
                </IconButton>
              </SoftBox>
            </Tooltip>
          </SoftBox>
        )}
      </SoftBox>
    </SoftBox>
  );
}

export default ImageEdit;
