import {BusinessItemTypes} from "helpers/enums/business-item.enum";
import FormNames from "layouts/pages/home/schemas/form-names.enum"

const useFormFields = (t) => {

    const businessItemOptions = [
        {value: BusinessItemTypes.PERSONAL_COACH, label: t('businessItemOptions.personalCoach')},
        {value: BusinessItemTypes.SPORT_FACILITY, label: t('businessItemOptions.sportFacility')},
        {value: BusinessItemTypes.GROUP_ACTIVITY, label: t('businessItemOptions.groupActivity')},
        {value: BusinessItemTypes.EVENT, label: t('businessItemOptions.event')},
    ];

    const genderOptions = [
        {value: "all", label: t('genderOptions.all')},
        {value: "man", label: t('genderOptions.menOnly')},
        {value: "woman", label: t('genderOptions.womenOnly')},
    ];

    const ratingScoreOptions = [
        {value: 4, label: "4+"},
        {value: 3, label: "3+"},
        {value: 2, label: "2+"},
        {value: 1, label: "1+"},
        {value: 0, label: t('ratingScoreOptions.unrated')},
    ];

    return {
        [FormNames.SEARCH]: {
            businessName: {
                name: "businessName",
                label: t('searchForm.businessName'),
                type: "text",
            },
            location: {
                name: "location",
                label: t('searchForm.location'),
                type: "text",
            },
            category: {
                name: "category",
                label: t('searchForm.category'),
                options: businessItemOptions,
                default: businessItemOptions[0].value,
                placeholder: t('searchForm.placeholderText'),
            },
            searchGender: {
                name: "gender",
                label: t('searchForm.genderPolicy'),
                options: genderOptions,
                placeholder: t('searchForm.placeholderText'),
            },
            searchAdditional: {
                name: "additional",
                label: t('searchForm.additional'),
            },
            searchSkillLevel: {
                name: "skillLevel",
                label: t('searchForm.skillLevel'),
            },
            searchAgeCategory: {
                name: "ageCategory",
                label: t('searchForm.ageCategory'),
            },
            searchSport: {
                name: "sport",
                label: t('searchForm.sport'),
            },
            reviewScore: {
                name: "reviewScore",
                label: t('searchForm.reviewScore'), // TODO: Change to ratingScore
                options: ratingScoreOptions
            }
        },
        [FormNames.SIMPLE_SEARCH]: {
            businessNameSimpleSearch: {
                name: "businessName",
                label: t('searchForm.businessName'),
                type: "text",
            },
            locationSimpleSearch: {
                name: "location",
                label: t('searchForm.location'),
                type: "text",
            },
            categorySimpleSearch: {
                name: "category",
                label: t('searchForm.category'),
                placeholder: t('searchForm.categoryPlaceholder'),
                options: businessItemOptions,
                default: businessItemOptions[0].value,
            },
        },
        [FormNames.MAKE_RESERVATION]: {
            firstName: {
                name: "firstName",
                label: t('makeReservation.firstName'),
                type: "text",
                placeholder: t('makeReservation.firstName'),
                errorMsg: t('makeReservation.firstNameRequiredErrorMsg')
            },
            lastName: {
                name: "lastName",
                label: t('makeReservation.lastName'),
                type: "text",
                placeholder: t('makeReservation.lastName'),
                errorMsg: t('makeReservation.lastNameRequiredErrorMsg')
            },
            email: {
                name: "email",
                label: t('makeReservation.email'),
                type: "email",
                placeholder: t('makeReservation.email'),
                errorMsg: t('makeReservation.emailRequiredErrorMsg'),
                invalidMsg: t('makeReservation.emailInvalidErrorMsg')
            },
            service: {
                name: "service",
                label: t('makeReservation.chooseService'),
                errorMsg: t('makeReservation.chooseServiceRequiredErrorMsg'),
            },
        }
    }
}

export default useFormFields;

