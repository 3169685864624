/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import { useState } from "react";
import PropTypes from "prop-types";
import MediaQuery from 'react-responsive'

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import WavedBlogCard from "examples/Cards/BlogCards/WavedBlogCard";

// Images
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

// Styles
import 'react-multi-carousel/lib/styles.css';
import 'styles/carousel.css';

function EventContent({ sponsoredEvents }) {

    //const [mainImage, setMainImage] = useState(); 
    const [galleryImages, setGalleryImages] = useState([]);
    const { t, i18n } = useTranslation();
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }

    };
    const GroupActivities = Object.values(sponsoredEvents);
    
  return (
      <SoftBox position="relative"
               zIndex={0} mt={5}
               px={{ xs: 1, sm: 0 }}
               //width="80%"
               align="justify"
               sx={{
                   width: {
                       xs: "100%", // theme.breakpoints.up('xs')
                       sm: "100%", // theme.breakpoints.up('sm')
                       md: "100%", // theme.breakpoints.up('md')
                       lg: "90%", // theme.breakpoints.up('lg')
                       xl: "90%", // theme.breakpoints.up('xl')
                   },
               }}>
          <Grid item xs={12} md={12} lg={12} padding="40px">
              <MediaQuery maxWidth={430}>
                    <SoftTypography variant="h4" align="center" fontWeight="bold" gutterBottom>
                        {t('headers.events_prim')}
                    </SoftTypography>
                </MediaQuery>
                <MediaQuery minWidth={1430}>
                    <SoftTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
                        {t('headers.events_prim')}
                    </SoftTypography>
                </MediaQuery>

              <SoftBox mb={2}>
                  <SoftTypography variant="body1" align="center" color="text">
                  {t('headers.events_sec')}
                  </SoftTypography>
              </SoftBox>
          </Grid>
          <Grid item xs={12} md={12} lg={12} padding="10px">
              <Grid item xs={12} md={6} lg={10} >
                  <SoftBox mt={0}>
                      <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000} className="custom-carousel-list">
                      {GroupActivities.map((item,index) => 
                        <div key={index} style={{margin:15}}><Grid item xs={12} md={6} lg={2}>
                        
                        
                        <WavedBlogCard
                                image={item.mediaPayload.mainImageUrl}
                                title={item.name}
                                description={item.description}
                                action={{
                                    type: "internal",
                                    route: "/event",
                                    color: "info",
                                    label: t('cardLabels.details'),
                                }}
                                eventID={String(item.id)}
                            />
                        </Grid></div>
                    )}
                      </Carousel>
                  </SoftBox>
              </Grid>
          </Grid>
      </SoftBox>
    );
}

// Typechecking props for the PricingCards
EventContent.propTypes = {
    sponsoredEvents: PropTypes.array.isRequired,
};

export default EventContent;
