/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
import { FieldArray } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import FormField from "components/common/FormField";
import SoftButton from "components/SoftButton";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { createTheme, ThemeProvider } from "@mui/material";
import dayjs from 'dayjs';
import inputBase from "assets/theme/components/form/inputBase";
import getFormFields from "layouts/pages/business/edit-business/schemas/form";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import { useTranslation } from "react-i18next";
import theme from "assets/theme";

function WorkingHoursAndScheduleEdit({ values, errors, touched, type, setFieldValue }) {
  const { t, i18n } = useTranslation();
  const [selectedTypeValue, setSelectedTypeValue] = useState(BusinessItemTypes.PERSONAL_COACH);
  const {formFields} = getFormFields(t);

  const { palette, functions } = theme;
  const { inputColors, dark } = palette;
  const { pxToRem, boxShadow } = functions;

  const createdTheme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            ...inputBase.styleOverrides.root,
            "&.Mui-focused": {
              borderColor: inputColors.borderColor.focus,
              paddingLeft: pxToRem(12),
              paddingRight: pxToRem(12),
              boxShadow: boxShadow([0, 0], [0, 2], inputColors.boxShadow, 1),
              outline: 0,
            },

            '& .MuiInputBase-input::placeholder': {
              color: `${dark.main} !important`,
            },

            "& fieldset": {
              border: "none !important",
              "&.Mui-focused": {
                borderColor: inputColors.borderColor.focus,
                paddingLeft: pxToRem(12),
                paddingRight: pxToRem(12),
                boxShadow: boxShadow([0, 0], [0, 2], inputColors.boxShadow, 1),
                outline: 0,
              },
            },

            input: {
              ...inputBase.styleOverrides.input,
            },
          }
        }
      }
    },
  });

  const renderAdditionalData = (values, errors, touched, setFieldValue) => {
    switch (selectedTypeValue) {
      case BusinessItemTypes.SPORT_FACILITY:
        return (
          <SoftBox >
         <FieldArray
                  name={formFields.workingHours.name}
                  error={errors.workingHours}
                  render={arrayHelpers => (
                    <SoftBox>
                      {values.workingHours?.map((workingHoursElem, index) => (
                        <Grid container spacing={3} key={index}>
                          <Grid item xs={12} sm={5}>
                            <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {formFields.workingHours.props.day.label}
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                              menuPortalTarget={document.querySelector('body')}
                              name={`workingHours[${index}].day`}
                              options={formFields.workingHours.props.day.options}
                              onChange={(event) => {
                                setFieldValue(`workingHours[${index}].day`, event);
                              }}
                              value={values.workingHours[index].day}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                {formFields.workingHours.props.startTime.label}
                              </SoftTypography>
                            </SoftBox>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <ThemeProvider theme={createdTheme}>
                                {console.log(values.workingHours)}
                                {console.log(values.workingHours[index].startTime)}

                                <TimePicker
                                  value={dayjs(values.workingHours[index].startTime)}
                                  onChange={(newTime) => {
                                    setFieldValue(`workingHours[${index}].startTime`, dayjs(newTime))
                                  }}
                                  ampm={false}
                                  renderInput={(params) => <TextField {...params}
                                  />}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                {formFields.workingHours.props.endTime.label}
                              </SoftTypography>
                            </SoftBox>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <ThemeProvider theme={createdTheme}>
                                <TimePicker
                                  value={dayjs(values.workingHours[index].endTime)}
                                  onChange={(newTime) => {
                                    setFieldValue(`workingHours[${index}].endTime`, dayjs(newTime))
                                  }}
                                  ampm={false}
                                  renderInput={(params) => <TextField {...params}
                                  />}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={1}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                  {t('businessItem.editPage.action')}
                              </SoftTypography>
                            </SoftBox>
                            <Tooltip title={t('buttonLabels.remove')} placement="bottom" arrow>
                              <SoftBox>
                                <IconButton variant="gradient" color="error" size="medium"
                                  disabled={values.workingHours.length <= 1}
                                  onClick={() => {
                                    if (values.workingHours.length > 1) {
                                      arrayHelpers.remove(index)
                                    }
                                  }}>
                                  <DeleteIcon sx={{ fontWeight: "bold" }}>delete</DeleteIcon>
                                </IconButton>
                              </SoftBox>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ))}
                      <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
                        <Tooltip title={t('buttonLabels.add')} placement="bottom" arrow>
                          <SoftButton color="secondary" size="small"
                            onClick={() => arrayHelpers.push({
                              day: formFields.workingHours.props.day.default,
                              startTime: "",
                              endTime: ""
                            })}
                          >
                            + {t('businessItem.editPage.addButton')}
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  )}
                />
          </SoftBox>)
      case BusinessItemTypes.GROUP_ACTIVITY:
        return (
          <SoftBox >
             <FieldArray
                  name={formFields.schedule.name}
                  error={errors.schedule}
                  render={arrayHelpers => (
                    <SoftBox width="100%">
                      {values.schedule?.map((scheduleElem, index) => (
                        <Grid container spacing={3} key={index}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={formFields.schedule.props.description.type}
                              label={formFields.schedule.props.description.label}
                              name={`schedule[${index}].description`}
                              placeholder={formFields.schedule.props.description.placeholder}
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                {formFields.schedule.props.startTime.label}
                              </SoftTypography>
                            </SoftBox>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <ThemeProvider theme={createdTheme}>
                                <TimePicker
                                  value={dayjs(values.schedule[index].startTime)}
                                  onChange={(newTime) => {
                                    setFieldValue(`schedule[${index}].startTime`, dayjs(newTime))
                                  }}
                                  ampm={false}
                                  renderInput={(params) => <TextField {...params}
                                  />}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography component="label" variant="caption" textTransform="capitalize" fontWeight="bold">
                                {formFields.schedule.props.endTime.label}
                              </SoftTypography>
                            </SoftBox>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <ThemeProvider theme={createdTheme}>
                                <TimePicker
                                  value={dayjs(values.schedule[index].endTime)}
                                  onChange={(newTime) => {
                                    setFieldValue(`schedule[${index}].endTime`, dayjs(newTime))
                                  }}
                                  ampm={false}
                                  renderInput={(params) => <TextField {...params}
                                  />}
                                />
                              </ThemeProvider>
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                  {t('businessItem.editPage.action')}
                              </SoftTypography>
                            </SoftBox>
                            <Tooltip title={t('buttonLabels.remove')} placement="bottom" arrow>
                              <SoftBox>
                                <IconButton variant="gradient" color="error" size="medium"
                                  disabled={values.schedule.length <= 1}
                                  onClick={() => {
                                    if (values.schedule.length > 1) {
                                      arrayHelpers.remove(index)
                                    }
                                  }}>
                                  <DeleteIcon sx={{ fontWeight: "bold" }}>{t('businessItem.editPage.deleteTooltip')}</DeleteIcon>
                                </IconButton>
                              </SoftBox>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ))}
                      <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
                        <Grid container spacing={3}>
                          <Grid item sm={10}></Grid>
                          <Grid item sm={2}>
                            <Tooltip title={t('buttonLabels.add')} placement="bottom" arrow>
                              <SoftButton color="secondary" size="small" style={{ marginRight: "42px" }}
                                onClick={() => arrayHelpers.push({
                                  description: "",
                                  startTime: "",
                                  endTime: ""
                                })}
                              >
                                + {t('businessItem.editPage.addButton')}
                              </SoftButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  )}
                />
          </SoftBox>)
      default:
        break;
    }
  }

  useEffect(() => {
    const loadData = async () => {
      if (type) {
        setSelectedTypeValue(type);
      }
    }
    loadData();
  }, []);

  return (
    <SoftBox p={3} display="grid">
      {selectedTypeValue == BusinessItemTypes.SPORT_FACILITY && (
        <SoftTypography variant="h5">{t('businessItem.editPage.workingHours')}</SoftTypography>
      )}
      {selectedTypeValue == BusinessItemTypes.GROUP_ACTIVITY && (
        <SoftTypography variant="h5">{t('businessItem.editPage.schedule')}</SoftTypography>
      )}
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderAdditionalData(values, errors, touched, setFieldValue)}
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default WorkingHoursAndScheduleEdit;
