/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import Footer from "layouts/pages/home/components/Footer";

// Form schemas
import initialValuesSchema from "layouts/pages/client/profile/schemas/initialValues";

// Page elements
import UserDataForm from "layouts/pages/client/profile/user-data-form";
import ChangePassword from "./change-password";
import Sidenav from "./side-nav";
import DeleteAccount from "./delete-account";

// Helpers
import { CreateGetRequest } from "helpers/back-request/helpers"
import { SuccessMessageSnackBar, ErrorMessageSnackBar } from "helpers/messages/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import PageLayout from "examples/LayoutContainers/PageLayout";
import HeaderMenu from "components/common/HeaderMenu";
import loader from "assets/images/moveon/loader-simple.svg";
import {useTranslation} from "react-i18next";


function ClientProfile() {
  const { t, i18n } = useTranslation();
  const { initialValues, populateInitialValues } = initialValuesSchema(t);

  const [showLoader, setShowLoader] = useState(false);
  const [initialValuesData, setInitialValuesData] = useState(initialValues);
  // Error messages
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = (message) => {
    setErrorMessage(message);
    setErrorSB(true);
  }
  const closeErrorSB = () => setErrorSB(false);
  // Success messages
  const [successMessage, setSuccessMessage] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = (message) => {
    setSuccessMessage(message);
    setSuccessSB(true);
  }
  const closeSuccessSB = () => setSuccessSB(false);

  const getClientProfileData = async () => {
    // Get client info from local storage
    const userLoginData = localStorage.getItem("user");
    const userLoginDataObj = JSON.parse(userLoginData);

    try {
      console.log("getClientProfileData")
      // Get client info from database
      const response = await CreateGetRequest(BackRequests.GET_USER + userLoginDataObj.userId);
      const userData = response.data.data;
      console.log(userData)

      let userProfileImage = null;
      if (userData.accountData.profileImage) {
        const userProfileImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + userData.accountData.profileImage, { responseType: 'blob' });
        userProfileImage = userProfileImageResponse.data;
      }

      return populateInitialValues(userData, userProfileImage);
    } catch (error) {
      console.log("error")
      console.log(error)
      return populateInitialValues(userLoginDataObj);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setShowLoader(true);
      const clientData = await getClientProfileData();
      setInitialValuesData(clientData);
      setShowLoader(false);
    }
    loadData();
  }, []);

  const setResponseMessage = (message, isError) => {
    if (isError) {
      openErrorSB(message);
    } else {
      openSuccessSB(message);
    }
  };

  return (
    <PageLayout>
      <SoftBox xs={12} md={12} lg={12} align="center">
        <HeaderMenu />
      </SoftBox>
      <SoftBox pt={3} pb={8} flex="1" display="flex" sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
      })}>
        {!showLoader &&
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Sidenav />
            </Grid>
            <Grid item xs={12} lg={9}>
              <SoftBox mb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <UserDataForm initialValuesData={initialValuesData} setResponseMessage={setResponseMessage}
                      spacing={3} />
                  </Grid>
                  <Grid item xs={12}>
                    <ChangePassword spacing={3} setResponseMessage={setResponseMessage} />
                  </Grid>
                  <Grid item xs={12}>
                    <DeleteAccount setResponseMessage={setResponseMessage} spacing={3} />
                  </Grid>
                </Grid>
              </SoftBox>
            </Grid>
          </Grid>
        }
        {showLoader &&
          <SoftBox textAlign="center" sx={{ margin: "auto", height: "100%" }}>
            <img src={loader} alt="Loading..." className="loader" />
          </SoftBox>}
        {ErrorMessageSnackBar({ title: "Error", errorMessage, errorSB, closeErrorSB })}
        {SuccessMessageSnackBar({ title: "Success", successMessage, successSB, closeSuccessSB })}
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

export default ClientProfile;