/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import FormField from "components/common/FormField";
import SoftBox from "components/SoftBox";

import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";

// Form schemas
import getFormFields from "layouts/pages/business/edit-business/schemas/form";
import { FieldArray } from "formik";
import Tooltip from "@mui/material/Tooltip";
import SoftButton from "components/SoftButton";
import { countryOptions } from "layouts/pages/business/business-form-helpers";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import { useTranslation } from "react-i18next";

function AddressEdit({ values, errors, touched, type }) {
  const { t, i18n } = useTranslation();
  const [isFullWidth, setIsFullWidth] = useState(false);
  const {formFields} = getFormFields(t);

  useEffect(() => {
    if(type && type != BusinessItemTypes.SPORT_FACILITY && type != BusinessItemTypes.GROUP_ACTIVITY) {
      setIsFullWidth(true)
    }
  }, []);

  return (
    <SoftBox p={3} display="grid">
      <SoftTypography variant="h5">
        {t('businessItem.editPage.address')}
      </SoftTypography>
      <FieldArray
        name={formFields.addresses.name}
        error={errors.addresses}
        render={arrayHelpers => (
          <SoftBox mt={1.625}>
            {values.addresses?.map((addressElem, index) => (
              <SoftBox key={"address=" + index}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={11}>
                    <Grid container columnSpacing={3}>
                      <Grid item xs={12} sm={isFullWidth ? 4 : 7}>
                        <FormField
                          type={formFields.addresses.props.addressLine.type}
                          label={formFields.addresses.props.addressLine.label}
                          name={`addresses[${index}].addressLine`}
                          value={values.addresses[index].addressLine}
                          placeholder={formFields.addresses.props.addressLine.placeholder}
                          error={errors.addressLine}
                        />
                      </Grid>
                      <Grid item xs={12} sm={isFullWidth ? 2 : 5}>
                        <FormField
                          type={formFields.addresses.props.addressNumber.type}
                          label={formFields.addresses.props.addressNumber.label}
                          name={`addresses[${index}].addressNumber`}
                          value={values.addresses[index].addressNumber}
                          placeholder={formFields.addresses.props.addressNumber.placeholder}
                          error={errors.addressNumber}
                        />
                      </Grid>
                      <Grid item xs={12} sm={isFullWidth ? 2 : 5}>
                        <FormField
                          type={formFields.addresses.props.city.type}
                          label={formFields.addresses.props.city.label}
                          name={`addresses[${index}].city`}
                          value={values.addresses[index].city}
                          placeholder={formFields.addresses.props.city.placeholder}
                          error={errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} sm={isFullWidth ? 2 : 2}>
                        <FormField
                          type={formFields.addresses.props.zipCode.type}
                          label={formFields.addresses.props.zipCode.label}
                          name={`addresses[${index}].zipCode`}
                          value={values.addresses[index].zipCode}
                          placeholder={formFields.addresses.props.zipCode.placeholder}
                          error={errors.zipCode}
                        />
                      </Grid>
                      <Grid item xs={12} sm={isFullWidth ? 2 : 5}>
                        {/* [CHANGE COUNTRY TO LIST] remove below FormField and uncomment previous */}
                        <FormField
                          type={formFields.addresses.props.country.type}
                          label={formFields.addresses.props.country.label}
                          name={`addresses[${index}].country`}
                          value={values.addresses[index].country}
                          placeholder={formFields.addresses.props.country.placeholder}
                          error={errors.country}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={1} display="flex" alignItems="center" justifyContent="center">
                    {/* <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Action
                    </SoftTypography>
                  </SoftBox> */}
                    <Tooltip title={t('businessItem.editPage.removeAddress')} placement="bottom" arrow>
                      <SoftBox>
                        <IconButton variant="gradient" color="error" size="medium"
                          disabled={values.addresses.length <= 1}
                          onClick={() => {
                            if (values.addresses.length > 1) {
                              arrayHelpers.remove(index)
                            }
                          }}>
                          <DeleteIcon sx={{ fontWeight: "bold" }}>{t('businessItem.editPage.deleteTooltip')}</DeleteIcon>
                        </IconButton>
                      </SoftBox>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Divider />
              </SoftBox>
            ))}
            <SoftBox mt={1} width="100%" display="flex" justifyContent="end">
              <Tooltip title={t('businessItem.editPage.addAddress')} placement="bottom" arrow>
                <SoftButton color="secondary" size="small"
                  onClick={() => arrayHelpers.push({
                    addressLine: "",
                    addressNumber: "",
                    city: "",
                    country: countryOptions[0].label,
                    zipCode: "",
                  })}
                >
                   {t('businessItem.editPage.addButton')}
                </SoftButton>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        )}
      />
    </SoftBox>
  );
}

export default AddressEdit;
