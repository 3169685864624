/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import moveonLogo from "assets/images/moveon/moveon-logo-orange.svg";
import proposalPDF from "assets/files/Predlog-za-partnere.pdf";
import PRIVACY_POLICY_EN from "assets/files/Privacy_Policy_ENG.pdf";
import PRIVACY_POLICY_RS from "assets/files/Privacy_Policy_SRB.pdf";
import TERMS_AND_CONDITIONS_EN from "assets/files/Terms_and_conditions_ENG.pdf";
import TERMS_AND_CONDITIONS_SR from "assets/files/Terms_and_condtions_SRB.pdf";


// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import X from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import WavedBlogCardWavesRoot from "../WavedBlogCardGym/WavedBlogCardWavesRoot";
import { Link } from "react-router-dom";
import RoutesNames from "helpers/enums/routes-map.enum";

function Footer() {
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();
  const currentLanguage = i18n.language;

  const termsAndConditionsLink = currentLanguage === "en" ? TERMS_AND_CONDITIONS_EN : TERMS_AND_CONDITIONS_SR;
  const privacyPolicyLink = currentLanguage === "en" ? PRIVACY_POLICY_EN : PRIVACY_POLICY_RS;

  return (
    <SoftBox component="footer" mt={7}>
      <SoftBox position="relative" height="3.125rem" overflow="hidden" mt={-6.25}>
        <SoftBox position="absolute" width="100%" top={0} zIndex={1}>
          <SoftBox
            component="svg"
            position="relative"
            width="100%"
            height="3.125rem"
            minHeight="3.125rem"
            maxHeight="3.125rem"
            mb={-0.875}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 40"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="card-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <WavedBlogCardWavesRoot>
              <use xlinkHref="#card-wave" x="48" y="-5" fill="rgba(	255, 232, 187,0.30" />
              <use xlinkHref="#card-wave" x="48" y="7" fill="rgba(	255, 232, 187,0.35)" />
              <use xlinkHref="#card-wave" x="48" y="9" fill="rgba(	255, 232, 187,0.25)" />
              <use xlinkHref="#card-wave" x="48" y="13" fill="rgba(	255, 232, 187,0.20)" />
              <use xlinkHref="#card-wave" x="48" y="17" fill="rgba(	255, 232, 187,0.15)" />
              <use xlinkHref="#card-wave" x="48" y="20" fill="rgba(	255, 232, 187,0.99)" />
            </WavedBlogCardWavesRoot>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox bgColor="#FFE8BB">
        <Grid container justifyContent="center">
          <Grid item xs={6} md={3} lg={2} justifyContent="center" display="flex">
            <SoftBox component={Link} to={RoutesNames.HOME} display="flex" alignItems="center">
              <img src={moveonLogo}
                alt="Move On"
                style={{ margin: "20px", height: "7vh" }}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <SoftBox display="flex" justifyContent="center" flexWrap="wrap" my={3}>
                  <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
                    <SoftTypography component="a" href="/about-us" variant="body2" color="primary">
                      {t('navbar.about')}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
                    <SoftTypography component="a" href={termsAndConditionsLink} target="_blank" variant="body2" color="primary">
                      {t('navbar.terms')}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
                    <SoftTypography component="a" href={privacyPolicyLink} target="_blank" variant="body2" color="primary">
                      {t('navbar.privacy')}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
                    <SoftTypography component="a" href={proposalPDF} target="_blank" variant="body2" color="primary">
                      {t('navbar.proposalForPartners')}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mr={{ xs: 2, lg: 3, xl: 6 }}>
                    <SoftTypography component="a" href="/home" variant="body2" color="primary">
                      {t('navbar.contact')}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12} lg={12}>
                <SoftBox display="flex" justifyContent="center" flexWrap="wrap">
                  <SoftBox mr={3} color="primary">
                    <FacebookIcon fontSize="medium" />
                  </SoftBox>
                  <SoftBox mr={3} color="primary">
                    <X fontSize="medium" />
                  </SoftBox>
                  <SoftBox mr={3} color="primary">
                    <InstagramIcon fontSize="medium" />
                  </SoftBox>
                </SoftBox>
                <Divider />
              </Grid>
              <Grid item xs={12} lg={12}>
                <SoftBox mb={2}>
                  <SoftTypography variant="body2" color="primary" textAlign="center">
                    {t('copyright.copyright')} &copy; 2023–{currentYear} MoveOn. {t('copyright.rightsReserved')}.
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={3} lg={2}></Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default Footer;
