/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "components/common/FormField";

// Form schemas
import getValidations from "layouts/pages/business/new-business/schemas/validations";
import {formDataFields} from "layouts/pages/business/new-business/schemas/form";
import getInitialValues from "layouts/pages/business/new-business/schemas/initialValues";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum";

import ButtonsSection from "layouts/pages/business/new-business/components/ButtonsSection";
import {useTranslation} from "react-i18next";

function ContactData({activeStep, isLastStep, handleNext, handleBack}) {
  const { t, i18n } = useTranslation();
  const { validations } = getValidations(t);
  const initial = getInitialValues(t).initial;

  // Form data
  const formValidation = validations[FormNames.CONTACT_DATA_FORM];
  const formFields = formDataFields(t)[FormNames.CONTACT_DATA_FORM];
  const initialValues = initial[FormNames.CONTACT_DATA_FORM];

  const handleSubmit = async (values, actions) => {
    try {
      console.log("ContactData HANDLE SUBMIT")
      console.log(values)
     
      handleNext({
        website: values.website,
        email: values.email,
        contactPhone: values.contactPhone,
        instagramAccount: values.instagramAccount,
        twitterAccount: values.twitterAccount,
        facebookAccount: values.facebookAccount,
        tiktokAccount: values.tiktokAccount,
      })
    } catch (error) {
      // TODO
      // openErrorSB(GetErrorMessage(error));
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      {({values, errors, touched, isSubmitting, resetForm, setFieldValue}) => (
        <Form id={FormNames.CONTACT_DATA_FORM} autoComplete="off">
          <SoftBox>
            <SoftTypography variant="h5" fontWeight="bold">
                {t("form.contactData.contactData")}
            </SoftTypography>
            <SoftBox mt={2}>
              <Grid container>
                <Grid item xs={12}>
                  <FormField
                    type={formFields.website.type}
                    label={formFields.website.label}
                    name={formFields.website.name}
                    value={values.website}
                    placeholder={formFields.website.placeholder}
                    error={errors.website && touched.website}
                    success={values.website.length > 0 && !errors.website}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type={formFields.email.type}
                    label={formFields.email.label}
                    name={formFields.email.name}
                    value={values.email}
                    placeholder={formFields.email.placeholder}
                    error={errors.email && touched.email}
                    success={values.email.length > 0 && !errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    type={formFields.contactPhone.type}
                    label={formFields.contactPhone.label}
                    name={formFields.contactPhone.name}
                    value={values.contactPhone}
                    placeholder={formFields.contactPhone.placeholder}
                    error={errors.contactPhone && touched.contactPhone}
                    success={values.contactPhone.length > 0 && !errors.contactPhone}
                  />
                </Grid>
                <Grid item xs={12} sm={6} pr={1}>
                  <FormField
                    type={formFields.instagramAccount.type}
                    label={formFields.instagramAccount.label}
                    name={formFields.instagramAccount.name}
                    value={values.instagramAccount}
                    placeholder={formFields.instagramAccount.placeholder}
                    error={errors.instagramAccount && touched.instagramAccount}
                    success={values.instagramAccount.length > 0 && !errors.instagramAccount}
                  />
                </Grid>
                <Grid item xs={12} sm={6} pl={1}>
                  <FormField
                    type={formFields.facebookAccount.type}
                    label={formFields.facebookAccount.label}
                    name={formFields.facebookAccount.name}
                    value={values.facebookAccount}
                    placeholder={formFields.facebookAccount.placeholder}
                    error={errors.facebookAccount && touched.facebookAccount}
                    success={values.facebookAccount.length > 0 && !errors.facebookAccount}
                  />
                </Grid>
                <Grid item xs={12} sm={6} pr={1}>
                  <FormField
                    type={formFields.twitterAccount.type}
                    label={formFields.twitterAccount.label}
                    name={formFields.twitterAccount.name}
                    value={values.twitterAccount}
                    placeholder={formFields.twitterAccount.placeholder}
                    error={errors.twitterAccount && touched.twitterAccount}
                    success={values.twitterAccount.length > 0 && !errors.twitterAccount}
                  />
                </Grid>
                <Grid item xs={12} sm={6} pl={1}>
                  <FormField
                    type={formFields.tiktokAccount.type}
                    label={formFields.tiktokAccount.label}
                    name={formFields.tiktokAccount.name}
                    value={values.tiktokAccount}
                    placeholder={formFields.tiktokAccount.placeholder}
                    error={errors.tiktokAccount && touched.tiktokAccount}
                    success={values.tiktokAccount.length > 0 && !errors.tiktokAccount}
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <ButtonsSection activeStep={activeStep} isLastStep={isLastStep} handleBack={handleBack} isSubmitting={isSubmitting} t={t} />
          </SoftBox>
        </Form>
      )}
    </Formik>
  );
}

export default ContactData;
