/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const formSchema = (t) => {
  const genderOptions = [
    {value: "male", label: t('manageAccount.profileInformation.genderOptions.male')},
    {value: "female", label: t('manageAccount.profileInformation.genderOptions.female')},
    {value: "neutral", label: t('manageAccount.profileInformation.genderOptions.other')},
  ];

  const interestOptions = [
    {value: "yoga", label: "Yoga"},
    {value: "swimming", label: "Swimming"},
    {value: "running", label: "Running"},
    {value: "crossfit", label: "Crossfit"},
    {value: "martial-arts", label: "Martial arts"},
  ];

  const formFields = {
    firstName: {
      name: "firstName",
      label: t('manageAccount.profileInformation.firstNameMandatory'),
      type: "text",
      placeholder: t('manageAccount.profileInformation.firstNamePlaceholder'),
      errorMsg: t('manageAccount.profileInformation.firstNameRequiredErrorMsg'),
    },
    lastName: {
      name: "lastName",
      label: t('manageAccount.profileInformation.lastNameMandatory'),
      type: "text",
      placeholder: t('manageAccount.profileInformation.lastNamePlaceholder'),
      errorMsg: t('manageAccount.profileInformation.lastNameRequiredErrorMsg'),
    },
    email: {
      name: "email",
      label: t('manageAccount.profileInformation.emailMandatory'),
      type: "email",
      placeholder: t('manageAccount.profileInformation.emailPlaceholder'),
      errorMsg: t('manageAccount.profileInformation.emailRequiredErrorMsg'),
      invalidMsg: t('manageAccount.profileInformation.emailInvalidErrorMsg'),
    },
    gender: {
      name: "gender",
      options: genderOptions,
      errorMsg: t('manageAccount.profileInformation.genderRequiredErrorMsg')
    },
    age: {
      name: "age",
      label: t('manageAccount.profileInformation.ageMandatory'),
      type: "number",
      errorMsg: t('manageAccount.profileInformation.ageRequiredErrorMsg'),
      invalidMsg: t('manageAccount.profileInformation.ageInvalidErrorMsg'),
      bounderyInvalidMsg: t('manageAccount.profileInformation.ageMinErrorMsg')
    },
    phoneNumber: {
      name: "phoneNumber",
      label: t('manageAccount.profileInformation.phoneNumber'),
      type: "text",
      placeholder: t('manageAccount.profileInformation.phoneNumberPlaceholder'),
      invalidMsg: t('manageAccount.profileInformation.phoneNumberInvalidErrorMsg'),
    },
    currentPassword: {
      name: "currentPassword",
      label: t('manageAccount.changePassword.currentPassword'),
      type: "password",
      placeholder: t('manageAccount.changePassword.currentPasswordPlaceholder'),
      errorMsg: t('manageAccount.changePassword.currentPasswordRequiredErrorMsg'),
      invalidMsg: t('manageAccount.changePassword.currentPasswordInvalidErrorMsg')
    },
    newPassword: {
      name: "newPassword",
      label: t('manageAccount.changePassword.newPassword'),
      type: "password",
      placeholder: t('manageAccount.changePassword.newPasswordPlaceholder'),
      errorMsg: t('manageAccount.changePassword.newPasswordRequiredErrorMsg'),
      invalidMsg: t('manageAccount.changePassword.newPasswordInvalidErrorMsg')
    },
    repeatPassword: {
      name: "repeatPassword",
      label: t('manageAccount.changePassword.confirmNewPassword'),
      type: "password",
      placeholder: t('manageAccount.changePassword.confirmNewPasswordPlaceholder'),
      errorMsg: t('manageAccount.changePassword.confirmNewPasswordRequiredErrorMsg'),
      invalidMsg: t('manageAccount.changePassword.confirmNewPasswordInvalidErrorMsg'),
    },
    profileImage: {
      name: "profileImage",
      type: "file",
      accept: "image/*"
    },
  };

  const getGenderOption = (optionName) => {
    return genderOptions.find((option) => option.value?.toLowerCase() == optionName?.toLowerCase())
  }

  return {formFields, interestOptions, getGenderOption}
}

export default formSchema;
