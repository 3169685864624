/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import { formDataFields } from "layouts/pages/business/new-business/schemas/form";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum"
import { getPhoneNumberRegex } from "helpers/general/helpers";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";

import { IG_REG_EXP, FB_REG_EXP, TW_X_REG_EXP, TIKTOK_REG_EXP } from "regex/socialMedia";

const getValidations = (t) => {

  const {
    [FormNames.CHOOSE_BUSINESS_TYPE]: {
      type
    },
    [FormNames.BUSINESS_INFO_FORM]: {
      name, description, eventDateStart, eventDateFinish, yearOfBirth, aboutMe, aboutPrograms
    },
    [FormNames.ADDRESS_FORM]: {
      addresses
    },
    [FormNames.PROGRAM_DETAILS_FORM]: {
      ageCategory, gender, skillLevel, sport, numberOfFreeTrainings, workingHours, schedule
    },
    [FormNames.SPORT_FACILITY_ADDITIONAL_FORM]: {
      additional
    },
    [FormNames.MEDIA_FORM]: {
      mainImage, coverImage
    },
    [FormNames.CONTACT_DATA_FORM]: {
      website, email, contactPhone,
      instagramAccount, facebookAccount, twitterAccount, tiktokAccount
    },
    [FormNames.PRICING_FORM]: {
      pricingList
    }
  } = formDataFields(t);

  const zipCodeRegExp = '^[A-Z0-9 -]{3,12}$';

  let selectedType = "";

  const validations = {
    [FormNames.CHOOSE_BUSINESS_TYPE]: Yup.object().shape({
      [type.name]: Yup.string().required(),
    }),
    [FormNames.BUSINESS_INFO_FORM]: Yup.object().shape({
      [name.name]: Yup.string().required(name.errorMsg),
      [description.name]: Yup.string(),
      [eventDateStart.name]: Yup.date().when([], {
        is: () => {
          return selectedType == BusinessItemTypes.EVENT
        },
        then: Yup.date().required(eventDateStart.errorMsg),
        otherwise: Yup.date()
      }),
      [eventDateFinish.name]: Yup.date().when([], {
        is: () => {
          return selectedType == BusinessItemTypes.EVENT
        },
        then: Yup.date().min(Yup.ref(eventDateStart.name), eventDateFinish.errorMsgInvalid),
        otherwise: Yup.date()
      }),
      [yearOfBirth.name]: Yup.number().integer().min(1900, yearOfBirth.errorMsg),
      [aboutMe.name]: Yup.string().when([], {
        is: () => {
          return selectedType == BusinessItemTypes.PERSONAL_COACH
        },
        then: Yup.string().required(aboutMe.errorMsg),
        otherwise: Yup.string()
      }),
      [aboutPrograms.name]: Yup.string().when([], {
        is: () => {
          return selectedType == BusinessItemTypes.GROUP_ACTIVITY
        },
        then: Yup.string().required(aboutPrograms.errorMsg),
        otherwise: Yup.string()
      }),
    }),
    [FormNames.PROGRAM_DETAILS_FORM]: Yup.object().shape({
      [ageCategory.name]: Yup.array(),
      [gender.name]: Yup.object(),
      [skillLevel.name]: Yup.array(),
      [sport.name]: Yup.array(),
      [numberOfFreeTrainings.name]: Yup.number().integer().min(0, numberOfFreeTrainings.errorMsg),
      [workingHours.name]: Yup.array().when([], {
        is: () => {
          return selectedType == BusinessItemTypes.SPORT_FACILITY
        },
        then: Yup.array()
            .of(
                Yup.object().shape({
                  [workingHours.props.day.name]: Yup.object().required(workingHours.props.day.errorMsg),
                  [workingHours.props.startTime.name]: Yup.string().required(workingHours.props.startTime.errorMsg),
                  [workingHours.props.endTime.name]: Yup.string().required(workingHours.props.endTime.errorMsg), //.min(Yup.ref(workingHours.props.startTime.name), workingHours.props.endTime.minErrorMsg)
                })
            )
            .min(1, workingHours.minimumErrorMsg),
        otherwise: Yup.array()
      }),
      [schedule.name]: Yup.array().when([], {
        is: () => {
          return selectedType == BusinessItemTypes.GROUP_ACTIVITY
        },
        then: Yup.array()
            .of(
                Yup.object().shape({
                  [schedule.props.description.name]: Yup.string().required(schedule.props.description.errorMsg),
                  [schedule.props.startTime.name]: Yup.string().required(schedule.props.startTime.errorMsg),
                  [schedule.props.endTime.name]: Yup.string().required(schedule.props.endTime.errorMsg),
                })
            )
            .min(1, schedule.minimumErrorMsg),
        otherwise: Yup.array()
      }),
    }),
    [FormNames.ADDRESS_FORM]: Yup.object().shape({
      [addresses.name]: Yup.array()
          .of(
              Yup.object().shape({
                [addresses.props.addressLine.name]: Yup.string().required(addresses.props.addressLine.errorMsg),
                [addresses.props.addressNumber.name]: Yup.string().required(addresses.props.addressNumber.errorMsg),
                [addresses.props.city.name]: Yup.string().required(addresses.props.city.errorMsg),
                [addresses.props.country.name]: Yup.string().required(addresses.props.country.errorMsg), // [CHANGE COUNTRY TO LIST] Yup.object().required(country.errorMsg)
                [addresses.props.zipCode.name]: Yup.string().matches(zipCodeRegExp, addresses.props.zipCode.errorMsgInvalid).required(addresses.props.zipCode.errorMsg),
              })
          )
          .required(pricingList.errorMsg)
          .min(1, pricingList.minimumErrorMsg),
    }),
    [FormNames.SPORT_FACILITY_ADDITIONAL_FORM]: Yup.object().shape({
      [additional.name]: Yup.array(),
    }),
    [FormNames.MEDIA_FORM]: Yup.object().shape({
      [mainImage.name]: Yup.mixed(), // required validation done in submit function
      [coverImage.name]: Yup.mixed(),
    }),
    [FormNames.CONTACT_DATA_FORM]: Yup.object().shape({
      [website.name]: Yup.string(), // TODO: sta ovde za validaciju web site urla?
      [email.name]: Yup.string().email(email.errorMsg),
      [contactPhone.name]: Yup.string().matches(getPhoneNumberRegex(), contactPhone.invalidMsg),
      [instagramAccount.name]: Yup.string().matches(IG_REG_EXP, instagramAccount.errorMsg),
      [facebookAccount.name]: Yup.string().matches(FB_REG_EXP, facebookAccount.errorMsg),
      [twitterAccount.name]: Yup.string().matches(TW_X_REG_EXP, twitterAccount.errorMsg),
      [tiktokAccount.name]: Yup.string().matches(TIKTOK_REG_EXP, tiktokAccount.errorMsg),
    }),
    [FormNames.PRICING_FORM]: Yup.object().shape({
      [pricingList.name]: Yup.array()
          .of(
              Yup.object().shape({
                [pricingList.props.description.name]: Yup.string().required(pricingList.props.description.errorMsg),
                [pricingList.props.price.name]: Yup.number().required(pricingList.props.price.errorMsg).min(1, pricingList.props.price.minimumErrorMsg),
                [pricingList.props.currency.name]: Yup.string(), // [CHANGE CURRENCY TO LIST] Yup.object()
                [pricingList.props.discount.name]: Yup.number().required(pricingList.props.discount.errorMsg).min(5, pricingList.props.discount.minimumErrorMsg),
              })
          )
          .required(pricingList.errorMsg)
          .min(1, pricingList.minimumErrorMsg),
    }),
  }

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const setSelectedType = (type) => {
    selectedType = type;
  };

  return {
    validations,
    preventMinus,
    preventPasteNegative,
    setSelectedType
  };

}

export default getValidations;