/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Form schemas
import getValidations from "layouts/pages/business/new-business/schemas/validations";
import { formDataFields } from "layouts/pages/business/new-business/schemas/form";
import getInitialValues from "layouts/pages/business/new-business/schemas/initialValues";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum";

import ButtonsSection from "layouts/pages/business/new-business/components/ButtonsSection";
import ImageEdit from "components/common/ImageEdit";
import { useRef, useState } from "react";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { IconButton, Tooltip } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import galleryPlaceholder from "assets/images/default-media-image.png";


function Media({ activeStep, isLastStep, handleNext, handleBack }) {
  const { t, i18n } = useTranslation();
  const [showMainImageError, setShowMainImageError] = useState(false);
  const [showSizeError, setShowSizeError] = useState(false);
  const [showImageTypeError, setImageTypeError] = useState(false);
  const { validations } = getValidations(t);
  const initial = getInitialValues(t).initial;

  // Form data
  const formValidation = validations[FormNames.MEDIA_FORM];
  const formFields = formDataFields(t)[FormNames.MEDIA_FORM];
  const initialValues = initial[FormNames.MEDIA_FORM];

  console.log(initialValues)
  const [images, setImages] = useState(initialValues?.gallery?.length > 0
    ? initialValues?.gallery.map((img) => { return { original: img } }) : []);

  const handleSubmit = async (values, actions) => {
    try {
      console.log("MEDIA HANDLE SUBMIT")
      console.log(values)

      if (!values.mainImage) {
        setShowMainImageError(true);
        return;
      }

      handleNext({
        mediaPayload: {
          mainImage: values.mainImage,
          coverImage: values.coverImage,
          gallery: images?.map((img) => img.original),
        }
      })
    } catch (error) {
      // openErrorSB(GetErrorMessage(error));
    }
  };

  const handleAddImage = (event) => {
    setShowSizeError(false);
    setImageTypeError(false);
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (event.target.files && event.target.files.length > 0) {
      console.log("handleAddImage")
      console.log(event)
      const uploadedImages = [...images];
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        if (file.size > maxSize) {
          setShowSizeError(true);
          return;
        }

        if (!allowedTypes.includes(file.type)) {
          setImageTypeError(true);
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          uploadedImages.push({
            original: reader.result
          });

          setImages([...uploadedImages]);
        }
      }
    }
  }

  const inputRef = useRef();
  const onChooseImg = (event) => {
    inputRef.current.click();
  }

  const deleteGalleryImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    console.log(updatedImages)
    setImages(updatedImages);
  };

  // Custom renderItem function for each gallery item
  const renderCustomItem = (item, index) => (
    <div>
      <img src={item.original} style={{ width: "60%" }} />
      <SoftBox textAlign="center" mt={1}>
        <Tooltip title={t('businessItem.editPage.removeImage')} placement="bottom" arrow>
          <SoftBox>
            <IconButton variant="gradient" color="primary" size="medium"
              onClick={() => deleteGalleryImage(images.indexOf(item))}>
              <DeleteIcon sx={{ fontWeight: "bold" }} fontSize="medium">{t('businessItem.editPage.deleteTooltip')}</DeleteIcon>                        </IconButton>
          </SoftBox>
        </Tooltip>
      </SoftBox>
    </div>
  );

  const selectImageAction = (image, type, values, setFieldValue) => {
    if (type == "main") {
      setFieldValue(formFields.mainImage.name, image);
      setShowMainImageError(false);
    }
    if (type == "cover") {
      setFieldValue(formFields.coverImage.name, image);
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isSubmitting, resetForm, setFieldValue }) => (
        <Form id={FormNames.MEDIA_FORM} autoComplete="off">
          <SoftBox>
            <SoftTypography variant="h5">{t('form.media.media')}</SoftTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SoftBox mt={3}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      {t('form.media.mainImageMandatory')}
                    </SoftTypography>
                  </SoftBox>
                  <ImageEdit type="main" initialImage={initialValues.mainImage} selectImageAction={selectImageAction} values={values} showMainImageError={showMainImageError} setFieldValue={setFieldValue}/>
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox mt={3}>
                  <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      {t('form.media.coverImage')}
                    </SoftTypography>
                  </SoftBox>
                  <ImageEdit type="cover" initialImage={initialValues.coverImage} selectImageAction={selectImageAction} values={values} setFieldValue={setFieldValue} />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={12}>
                <SoftBox mt={1} textAlign="center" justifyContent="center" display="flex" flexDirection="column">
                  <SoftBox mb={1} lineHeight={0} display="inline-block">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      {t('form.media.galleryImages')}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox height="100%" display="flex" flexDirection="column" width="50%" alignSelf="center" justifyContent="center">
                    {images && images?.length > 0 ? (
                      <SoftBox>
                        <Gallery
                          items={images}
                          renderItem={(item, index) => renderCustomItem(item, index)} // Using renderItem to customize each image
                          showFullscreenButton={false}
                          showThumbnails={false}
                          autoPlay={false}
                          showPlayButton={false}
                          showBullets={false}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                        {showSizeError && <SoftTypography color="error" variant="body2">{t('uploadImage.imageSizeError')}</SoftTypography>}
                        {showImageTypeError && <SoftTypography color="error" variant="body2">{t('uploadImage.imageTypeError')}</SoftTypography>}
                      </SoftBox>
                    ) : (
                      <SoftBox display="flex" justifyContent="center">
                        <SoftBox width="30%" display="flex" alignSelf="center" flexDirection="column">
                          <img src={galleryPlaceholder} width="100%"></img>
                        </SoftBox>
                        {showSizeError && <SoftTypography color="error" variant="body2">{t('uploadImage.imageSizeError')}</SoftTypography>}
                        {showImageTypeError && <SoftTypography color="error" variant="body2">{t('uploadImage.imageTypeError')}</SoftTypography>}
                      </SoftBox>
                    )}
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/webp"
                      ref={inputRef}
                      onChange={handleAddImage}
                      style={{ display: "none" }}
                      multiple
                    />
                    <SoftBox style={{ marginTop: "auto" }} width="100%" display="flex" justifyContent="end">
                      <Tooltip title={t('buttonLabels.addImage')} placement="bottom" arrow>
                        <SoftButton color="secondary" size="small"
                          onClick={onChooseImg}
                        >
                          {t('buttonLabels.plusAdd')}
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <ButtonsSection activeStep={activeStep} isLastStep={isLastStep} handleBack={handleBack} isSubmitting={isSubmitting} t={t} />
          </SoftBox>
        </Form>
      )}
    </Formik>
  );
}

export default Media;
