/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import SoftBox from "components/SoftBox";
import { Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";

import HomePin from '@mui/icons-material/LocationCity';
import ContactPhone from '@mui/icons-material/Phone';
import ContactEmail from '@mui/icons-material/Email';
import ContactSite from '@mui/icons-material/Language';
import {useTranslation} from "react-i18next";
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import ShopIcon from '@mui/icons-material/Shop';
import ParaglidingIcon from '@mui/icons-material/Paragliding';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PetsIcon from '@mui/icons-material/Pets';
import WifiIcon from '@mui/icons-material/Wifi';
import SpaIcon from '@mui/icons-material/Spa';
import WashIcon from '@mui/icons-material/Wash';
import DryCleaningIcon from '@mui/icons-material/DryCleaning';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import ConstructionIcon from '@mui/icons-material/Construction';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';								
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import PaymentsIcon from '@mui/icons-material/Payments';
import HvacIcon from '@mui/icons-material/Hvac';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FlagIcon from '@mui/icons-material/Flag';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';

function AdditionalInformationTab({ addresses, contactData, additional }) {
    const data = location.state;
    const { t, i18n } = useTranslation();
    return (
        <SoftBox m={2}>
            <Grid container spacing={2}>
                <Grid item lg={6}>
                    <SoftTypography variant="h6" mb={2}>
                    {t('tabs.tab_address_contact')}
                    </SoftTypography>
                    {addresses?.map((adr, index) => (
                        <SoftBox key="index" display="flex" alignItems="center" mb={1}><HomePin color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{adr?.addressLine + " " + adr?.addressNumber + ", " + adr?.zipCode + " " + adr?.city}</SoftTypography></SoftBox>
                    ))}
                    <SoftBox display="flex" alignItems="center" mb={1}><ContactPhone color="primary" size="medium" /><SoftTypography variant="body2" ml={1}> {contactData?.contactPhone ? contactData?.contactPhone : "/"}</SoftTypography></SoftBox>
                    <SoftBox display="flex" alignItems="center" mb={1}><ContactEmail color="primary" size="medium" /><SoftTypography variant="body2" ml={1}> {contactData?.email ? contactData?.email : "/"}</SoftTypography></SoftBox>
                    <SoftBox display="flex" alignItems="center" mb={1}><ContactSite color="primary" size="medium" /><SoftTypography variant="body2" ml={1}> {contactData?.website ? contactData?.website : "/"}</SoftTypography></SoftBox>
                </Grid>
                <Grid item lg={6}>
                    {additional?.length > 0 && (
                        <SoftTypography variant="h6" mb={2}>
                            Additional features
                        </SoftTypography>
                    ) }
                    <Grid container>
                        {additional?.sort((a, b) => a.key > b.key ? 1 : -1).map((item, i) => {
                            return <Grid item xs={12} lg={6} md={6} key={"additional-"+i}>
                                {
                                    (() => {
                                        if (item.value == "nutrition-plan" || item.value == "meal-planning" || item.value == "nutritional-advice" || item.value == "supplement-guidance" || item.value == "dietary-restrictions" || item.value == "vegan-options") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><MedicationLiquidIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "online-trainings") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><ShopIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "outdoor-trainings" || item.value == "individual-training" || item.value == "group-training" || item.value == "indoor-training") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><ParaglidingIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "parking") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><LocalParkingIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "pet-friendly") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><PetsIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "wi-fi") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><WifiIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "wellness") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><SpaIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "bath-cosmetics") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><WashIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "towels") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><DryCleaningIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "meal-planning") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><NoMealsIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "additional-equipment") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><ConstructionIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "t-shirts") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><CheckroomIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "medals") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><WorkspacePremiumIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "caffe-zone") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><LocalCafeIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "fresh-juices" || item.value == "refreshing-drinks" || item.value == "energy-smoothies" || item.value == "electrolyte-drinks" || item.value == "energy-drinks") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><LocalDrinkIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "healthy-snacks") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><MedicationLiquidIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "payment-cash" || item.value == "payment-cards") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><PaymentsIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "heating" || item.value == "air-conditioning") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><HvacIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "pregnancy-fitness") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><PregnantWomanIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "rehab-injury-recovery" || item.value == "training-individuals-special-needs" || item.value == "accessibility-disabilities") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><AssistWalkerIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "children-zone" ) {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><FamilyRestroomIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else if (item.value == "language-ger" || item.value == "language-ita" || item.value == "language-rus" || item.value == "language-srb" || item.value == "language-esp" || item.value == "language-eng" || item.value == "language-fra") {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><FlagIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        } else {
                                            return (
                                                <SoftBox display="flex" alignItems="center" mb={1}><FileDownloadDoneIcon color="primary" size="medium" /><SoftTypography variant="body2" ml={1}>{item.label}</SoftTypography></SoftBox>
                                            )
                                        }
										
                                    })()
                                }

                            </Grid>
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default AdditionalInformationTab;

