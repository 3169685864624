/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useTranslation} from "react-i18next";
import SoftBox from "components/SoftBox";
import searchBanner from "assets/images/moveon/banner/1-search.svg";
import calendarBanner from "assets/images/moveon/banner/2-calendar.svg";
import moveOnBanner from "assets/images/moveon/banner/3-move-on.svg";
import separator from "assets/images/moveon/banner/wavy-dash.svg";
import MediaQuery from "react-responsive";
import Carousel from "react-multi-carousel";
import SoftTypography from "components/SoftTypography";

function InstructionsBanner() {
    const {t, i18n} = useTranslation();

    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 2048},
            items: 4
        },
        desktop: {
            breakpoint: {max: 2048, min: 1024},
            items: 4
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    return (
        <SoftBox>
            <MediaQuery minWidth={465}>
                <SoftBox
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    padding="2rem"
                >
                    <SoftBox display="flex" alignItems="center" flexWrap="wrap" justifyContent="center">
                        <SoftBox
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            flexGrow={1}
                            maxWidth="25%"
                            marginRight={1}
                        >
                            <img
                                src={searchBanner}
                                alt="Search"
                                style={{ margin: "3px 0", height: "25vh", maxHeight: "170px", width: "auto" }}
                            />
                            <SoftTypography variant="body2">{t('instructions-banner.search')}</SoftTypography>
                        </SoftBox>

                        <SoftBox display="flex" justifyContent="center" alignItems="center" maxWidth="10%" margin={3}>
                            <img
                                src={separator}
                                style={{ margin: "3px 0", height: "15vh", maxHeight: "100px", width: "auto" }}
                            />
                        </SoftBox>

                        <SoftBox
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            flexGrow={1}
                            maxWidth="25%"
                            marginRight={1}
                        >
                            <img
                                src={calendarBanner}
                                alt="Reserve"
                                style={{ margin: "3px 0", height: "25vh", maxHeight: "170px", width: "auto" }}
                            />
                            <SoftTypography variant="body2">{t('instructions-banner.book')}</SoftTypography>
                        </SoftBox>

                        <SoftBox display="flex" justifyContent="center" alignItems="center" maxWidth="10%" margin={3}>
                            <img
                                src={separator}
                                style={{ margin: "3px 0", height: "15vh", maxHeight: "100px", width: "auto" }}
                            />
                        </SoftBox>

                        <SoftBox
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            flexGrow={1}
                            maxWidth="25%"
                        >
                            <img
                                src={moveOnBanner}
                                alt="Move On"
                                style={{ margin: "3px 0", height: "25vh", maxHeight: "170px", width: "auto" }}
                            />
                            <SoftTypography variant="body2">{t('instructions-banner.move')}</SoftTypography>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </MediaQuery>
            <MediaQuery maxWidth={464}>
                <SoftBox
                    width="100%"
                    // display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    minHeight="22.75rem"
                    marginTop="40px"
                >
                    <SoftBox px={1.5}>
                        <Carousel responsive={responsive} infinite={true} autoPlay={false} autoPlaySpeed={3000}>
                            <SoftBox width="75%">
                                <img
                                    src={searchBanner}
                                    alt="Search"
                                    style={{margin: "3px 0", height: "19vh"}}
                                />
                                <SoftTypography variant="body2">{t('instructions-banner.search')}</SoftTypography>
                            </SoftBox>
                            <SoftBox width="75%">
                                <img
                                    src={calendarBanner}
                                    alt="Reserve"
                                    style={{margin: "3px 0", height: "19vh"}}
                                />
                                <SoftTypography variant="body2">{t('instructions-banner.book')}</SoftTypography>
                            </SoftBox>
                            <SoftBox width="75%">
                                <img
                                    src={moveOnBanner}
                                    alt="Move On"
                                    style={{margin: "3px 0", height: "19vh"}}
                                />
                                <SoftTypography variant="body2">{t('instructions-banner.move')}</SoftTypography>
                            </SoftBox>
                        </Carousel>
                    </SoftBox>
                </SoftBox>
            </MediaQuery>
        </SoftBox>
    );
}

export default InstructionsBanner;