/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Helpers
import { CreatePostRequest, CreateDeleteRequest, GetErrorMessage } from "helpers/back-request/helpers"
import MessagesTexts from "helpers/messages/messages-texts";
import BackRequests from "helpers/back-request/back-requests.enum";
import RoutesNames from "helpers/enums/routes-map.enum";
import { sleep, clearUserDataFromLocalStorage } from "helpers/general/helpers";
import {useTranslation} from "react-i18next";

//TODO: Change account to profile or vice versa
function DeleteAccount({setResponseMessage}) {
  const { t } = useTranslation();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const navigate = useNavigate();

  const handleConfirmAccountDeletion = () => setIsConfirmed(true);

  const handleDeleteAccount = async () => {
    if (!isConfirmed) {
      setResponseMessage(MessagesTexts.ACCOUNT_DELETION_NOT_CONFIRMED, true);
      return;
    }

        try {
          await CreateDeleteRequest(BackRequests.DELETE_USER_PROFILE + JSON.parse(localStorage.getItem("userId")));
          setResponseMessage(MessagesTexts.SUCCESSFULLY_CHANGED_PROFILE_STATUS, false);
          await sleep(3);
            clearUserDataFromLocalStorage();
            navigate(RoutesNames.HOME);
        } catch (error) {
          setResponseMessage(GetErrorMessage(error), true);
        }
    }

    const handleDeactivateAccount = async () => {
        console.log("handleDeactivateAccount")
        try {
          await CreatePostRequest(BackRequests.DEACTIVATE_USER_PROFILE + JSON.parse(localStorage.getItem("userId")), null);
          setResponseMessage(MessagesTexts.SUCCESSFULLY_CHANGED_PROFILE_STATUS, false);
        } catch (error) {
          console.log(error)
          setResponseMessage(GetErrorMessage(error), true);
        }
    }

    return (
        <Card id="delete-account">
            <SoftBox p={3} lineHeight={1}>
                <SoftBox mb={1}>
                    <SoftTypography variant="h5">{t('manageAccount.deleteAccount.deleteAccount')}</SoftTypography>
                </SoftBox>
                <SoftTypography variant="button" color="text" fontWeight="regular">
                    {t('manageAccount.deleteAccount.deleteAccountText')}
                </SoftTypography>
            </SoftBox>
            <SoftBox
                pb={3}
                px={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
            >
                <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>
                    <Switch onChange={handleConfirmAccountDeletion}/>
                    <SoftBox ml={2} lineHeight={0}>
                        <SoftTypography display="block" variant="button" fontWeight="medium">
                            {t('manageAccount.deleteAccount.confirm')}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="text">
                            {t('manageAccount.deleteAccount.confirmDelete')}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
                <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                    {/* <SoftButton variant="outlined" color="secondary" onClick={handleDeactivateAccount}>
                        deactivate
                    </SoftButton> */}
                    <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                        <SoftButton variant="gradient" color="error" sx={{ height: "100%" }} onClick={handleDeleteAccount}>
                            {t('buttonLabels.deleteAccount')}
                        </SoftButton>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
        </Card>
    );
}

export default DeleteAccount;
