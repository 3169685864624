/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */


// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import { useRef, useState } from "react";
import borders from "assets/theme/base/borders";
import SoftTypography from "components/SoftTypography";
import { useTranslation } from "react-i18next";

function FileInput({ onImageSelected, showMainImageError }) {
    const { t, i18n } = useTranslation();
    const { borderRadius, borderWidth } = borders;
    const [showSizeError, setShowSizeError] = useState(false);
    const [showImageTypeError, setImageTypeError] = useState(false);


    const inputRef = useRef();

    const handleChange = (event) => {
        setShowSizeError(false);
        setImageTypeError(false);
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];

        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            const file = event.target.files[0]
            if (file.size > maxSize) {
                setShowSizeError(true);
                return;
            }

            if (!allowedTypes.includes(file.type)) {
                setImageTypeError(true);
                return;
            }

            reader.readAsDataURL(file);
            reader.onload = function (e) {
                onImageSelected(reader.result);
            }
        }
    }

    const onChooseImg = (event) => {
        inputRef.current.click();
    }

    return (
        <SoftBox>
            {/* Hidden file input element */}
            <input
                type="file"
                accept="image/jpeg, image/png, image/webp" 
                ref={inputRef}
                onChange={handleChange}
                style={{ display: "none" }}
            />

            {/* Button to trigger the file input dialog */}
            <SoftBox p={4} mb={2} borderColor={(showMainImageError || showSizeError || showImageTypeError) ? "error.main" : "secondary.main"} onClick={onChooseImg} borderRadius="lg" sx={{
                borderStyle: "dashed",
                borderWidth: borderWidth[2],
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <SoftTypography color="text" m={2} variant="button" fontWeight="regular">{t('uploadImage.clickToUpload')}</SoftTypography>
                {showMainImageError && <SoftTypography color="error" variant="body2">{t('uploadImage.requiredImage')}</SoftTypography>}
                {showSizeError && <SoftTypography color="error" variant="body2">{t('uploadImage.imageSizeError')}</SoftTypography>}
                {showImageTypeError && <SoftTypography color="error" variant="body2">{t('uploadImage.imageTypeError')}</SoftTypography>}
            </SoftBox>
            <SoftButton onClick={onChooseImg} color="secondary">
                {t('uploadImage.chooseButton')}
            </SoftButton>
        </SoftBox>
    );
}

export default FileInput;
