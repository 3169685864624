/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import getFormFields from "layouts/pages/business/edit-business/schemas/form";
import { countryOptions, genderOptions, getCountryLabel, getDayOptionByValue } from "../../business-form-helpers";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import { format } from "date-fns";

const getInitialValue = (t) => {
  const {formFields} = getFormFields(t);

  const {
    type,
    name, description, eventDateStart, eventDateFinish, yearOfBirth, aboutMe, aboutPrograms,
    ageCategory, gender, skillLevel, sport, numberOfFreeTrainings, workingHours, schedule,
    addresses, country,
    additional,
    mainImage, coverImage, galleryImages,
    website, email, contactPhone,
    instagramAccount, facebookAccount, twitterAccount, tiktokAccount,
    pricingList,
  } = formFields;


  const initialValues = {
    [type.name]: null,
    [name.name]: "",
    [description.name]: "",
    [eventDateStart.name]: "",
    [eventDateFinish.name]: "",
    [yearOfBirth.name]: 1900,
    [aboutMe.name]: "",
    [aboutPrograms.name]: "",
    [ageCategory.name]: [],
    [gender.name]: gender.default,
    [skillLevel.name]: [],
    [sport.name]: [],
    [numberOfFreeTrainings.name]: 0,
    [workingHours.name]: [{
      day: workingHours.props.day.default,
      startTime: "",
      endTime: ""
    }],
    [schedule.name]: [{
      description: "",
      startTime: "",
      endTime: ""
    }],
    [addresses.name]: [],
    [additional.name]: [],
    [mainImage.name]: null,
    [coverImage.name]: null,
    [galleryImages.name]: [],
    [website.name]: "",
    [email.name]: "",
    [contactPhone.name]: "",
    [instagramAccount.name]: "",
    [facebookAccount.name]: "",
    [twitterAccount.name]: "",
    [tiktokAccount.name]: "",
    [pricingList.name]: [{
      description: "",
      price: 0,
      currency: pricingList.props.currency.default,
      discount: 5,
      discountedPrice: 0
    }],
  }

  const populateInitialValues = (initData, mainImageData, coverImageData, galleryImagesData) => {
    const populatedInitialData = {
      [type.name]: initData.type ?? "", // ???
      [name.name]: initData.name ?? "",
      [description.name]: initData.description ?? "",
      [eventDateStart.name]: (initData.eventPayload?.dateStart && format(initData.eventPayload?.dateStart, "yyyy-MM-dd")) ?? "",
      [eventDateFinish.name]: (initData.eventPayload?.dateFinish && format(initData.eventPayload?.dateFinish, "yyyy-MM-dd")) ?? "", // mm/dd/yyyy
      [yearOfBirth.name]: initData.personalCoachPayload?.yearOfBirth ?? 1900,
      [aboutMe.name]: initData.personalCoachPayload?.aboutMe ?? "",
      [aboutPrograms.name]: initData.groupActivityPayload?.aboutPrograms ?? "",
      [ageCategory.name]: initData.ageCategories ?? [],
      [gender.name]: initData.gender ? genderOptions.find((item) => item.value == initData.gender) : gender.default,
      [skillLevel.name]: initData.skillLevels ?? [],
      [sport.name]: initData.sports ?? [],
      [numberOfFreeTrainings.name]: 0,
      [workingHours.name]: initData.sportFacilityPayload?.workingHours.map((workingHour) => {
        return {
          day: getDayOptionByValue(workingHour.day),
          startTime: workingHour.startTime,
          endTime: workingHour.endTime,
          id: workingHour.id
        }
      }) ?? [{
        day: workingHours.props.day.default,
        startTime: "",
        endTime: ""
      }],
      [schedule.name]: initData.groupActivityPayload?.schedule ?? [{
        description: "",
        startTime: "",
        endTime: ""
      }],
      [addresses.name]: (initData.addresses && initData.addresses.length > 0) ?
          initData.addresses?.map((addr) => {
            return {
              addressLine: addr.addressLine,
              addressNumber: addr.addressNumber,
              city: addr.city,
              country: getCountryLabel(addr.country),
              zipCode: addr.zipCode,
            }
          })
          : [{
            addressLine: "",
            addressNumber: "",
            city: "",
            country: countryOptions[0].label, // [CHANGE COUNTRY TO LIST] countryOptions[0]
            zipCode: "",
          }],
      [additional.name]: initData.additionals ?? [],
      [mainImage.name]: mainImageData ?? null,
      [coverImage.name]: coverImageData ?? null,
      [galleryImages.name]: galleryImagesData ?? [],
      [website.name]: initData.contactData?.website ?? "",
      [email.name]: initData.contactData?.email ?? "",
      [contactPhone.name]: initData.contactData?.contactPhone ?? "",
      [instagramAccount.name]: initData.socialAccounts?.instagramAccount ?? "",
      [facebookAccount.name]: initData.socialAccounts?.facebookAccount ?? "",
      [twitterAccount.name]: initData.socialAccounts?.twitterAccount ?? "",
      [tiktokAccount.name]: initData.socialAccounts?.tiktokAccount ?? "",
      [pricingList.name]: initData.pricelist ?? [{
        description: "",
        price: 0,
        currency: pricingList.props.currency.default,
        discount: 5,
        discountedPrice: 0
      }],
    };

    switch (initData.type) {
      case BusinessItemTypes.PERSONAL_COACH:
        populatedInitialData[numberOfFreeTrainings.name] = initData.personalCoachPayload?.numberOfFreeTrainings ?? 0;
        break;
      case BusinessItemTypes.SPORT_FACILITY:
        populatedInitialData[numberOfFreeTrainings.name] = initData.sportFacilityPayload?.numberOfFreeTrainings ?? 0;
        break;
      case BusinessItemTypes.GROUP_ACTIVITY:
        populatedInitialData[numberOfFreeTrainings.name] = initData.groupActivityPayload?.numberOfFreeTrainings ?? 0;
        break;
    }
    return populatedInitialData;
  };

  return { initialValues, populateInitialValues };
}

export default getInitialValue;
