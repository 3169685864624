/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import Footer from "layouts/pages/home/components/Footer";

import PageLayout from "examples/LayoutContainers/PageLayout";
import HeaderMenu from "components/common/HeaderMenu";

import { Formik, Form } from "formik";
import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";

import { useEffect, useRef, useState } from "react";
import getInitialValue from "layouts/pages/business/edit-business/schemas/initialValues";
import getValidations from "layouts/pages/business/edit-business/schemas/validations";
import getFormFields from "layouts/pages/business/edit-business/schemas/form";

import { Card, IconButton, List, ListItem, Tooltip } from "@mui/material";
import BusinessInfoEdit from "./components/BusinessInfoEdit";
import ContactDataEdit from "./components/ContactDataEdit";
import SportFacilityAdditionalEdit from "./components/SportFacilityAdditionalEdit";
import PricingEdit from "./components/PricingEdit";
import ProgramDetailsEdit from "./components/ProgramDetailsEdit";
import AddressEdit from "./components/AddressEdit";
import WorkingHoursAndScheduleEdit from "./components/WorkingHoursAndScheduleEdit";
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import ImageEdit from "components/common/ImageEdit";
import { CreatePostRequest } from "helpers/back-request/helpers";
import { SuccessMessageSnackBar } from "helpers/messages/helpers";
import { ErrorMessageSnackBar } from "helpers/messages/helpers";
import { GetErrorMessage } from "helpers/back-request/helpers";
import { useLocation } from "react-router-dom";
import loader from "assets/images/moveon/loader-simple.svg";
import AdminFooter from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useTranslation } from "react-i18next";
import { getCountryValue } from "../business-form-helpers";
import Gallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import DeleteIcon from '@mui/icons-material/Delete';
import { UploadImage } from "helpers/back-request/helpers";
import galleryPlaceholder from "assets/images/default-media-image.png";
import _ from 'lodash';

function EditBusiness({ businessItemId }) {
    const { t, i18n } = useTranslation();
    const [showLoader, setShowLoader] = useState(false);
    const location = useLocation();
    const [businessDataObj, setBusinessDataObj] = useState();
    const {initialValues, populateInitialValues} = getInitialValue(t);
    const {validations, setSelectedType} = getValidations(t);
    const [initialValuesData, setInitialValuesData] = useState(initialValues);
    const [isAddressBoxFullWidth, setIsAddressBoxFullWidth] = useState(false);
    const [showMainImageError, setShowMainImageError] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const [showSizeError, setShowSizeError] = useState(false);
    const [showImageTypeError, setImageTypeError] = useState(false);
    const [shouldReset, setShouldReset] = useState(false);
    const {formFields} = getFormFields(t);

    const inputRef = useRef();
    const onChooseImg = (event) => {
        inputRef.current.click();
    }

    const getBusinessData = async () => {
        try {
            // Get business data from database
            const response = await CreateGetRequest(BackRequests.GET_BUSINESS_ITEM_BY_ID + location.state.businessItemId);
            const businessData = response.data.data;
            setBusinessDataObj(businessData);
            setSelectedType(businessData.type);

            let mainImage = null;
            if (businessData.mediaPayload?.mainImage) {
                const mainImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + businessData.mediaPayload?.mainImage, { responseType: 'blob' });
                mainImage = URL.createObjectURL(mainImageResponse.data);
            }

            let coverImage = null;
            if (businessData.mediaPayload?.coverImage) {
                const coverImageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + businessData.mediaPayload?.coverImage, { responseType: 'blob' });
                coverImage = URL.createObjectURL(coverImageResponse.data);
            }

            let imagesArray = [];
            if (businessData.mediaPayload?.gallery) {
                for (let i = 0; i < businessData.mediaPayload.gallery.length; i++) {
                    const galleryImageId = businessData.mediaPayload.gallery[i];
                    if (galleryImageId == null) {
                        continue
                    }
                    const galleryImagesResponse = await CreateGetRequest(BackRequests.GET_MEDIA + galleryImageId, { responseType: 'blob' });
                    imagesArray.push({
                        original: URL.createObjectURL(galleryImagesResponse.data),
                        imageId: galleryImageId
                    })
                }
                setGalleryImages(imagesArray);
            }

            return {
                ...populateInitialValues(businessData, mainImage, coverImage, imagesArray),
                mediaImages: {
                    mainImage,
                    coverImage,
                }
            };
        } catch (error) {
            console.log("error")
            console.log(error)
            return initialValuesData;
        }
    };

    useEffect(() => {
        const loadData = async () => {
            setShowLoader(true);
            const businessData = await getBusinessData();
            setInitialValuesData(businessData);
            if (businessData.type != BusinessItemTypes.SPORT_FACILITY
                && businessData.type != BusinessItemTypes.GROUP_ACTIVITY) {
                setIsAddressBoxFullWidth(true);
            }
            setShowLoader(false);
        }
        loadData();
    }, []);

    // Error message
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSB, setErrorSB] = useState(false);

    const openErrorSB = (message) => {
        setErrorMessage(message);
        setErrorSB(true);
    };
    const closeErrorSB = () => setErrorSB(false);

    // Success messages
    const [successMessage, setSuccessMessage] = useState("");
    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = (message) => {
        setSuccessMessage(message);
        setSuccessSB(true);
    }
    const closeSuccessSB = () => setSuccessSB(false);

    const handleSubmit = async (values, actions) => {
        console.log("EDIT BUSINESS")
        console.log(values);
        try {
            let mainImageId = businessDataObj.mediaPayload?.mainImage;
            if (values.mainImage != initialValuesData.mediaImages?.mainImage) {
                mainImageId = await UploadImage(values.mainImage);
            }

            let coverImageId = businessDataObj.mediaPayload?.coverImage;
            if (values.coverImage != initialValuesData.mediaImages.coverImage) {
                if (values.coverImage == "") {
                    coverImageId = null;
                } else {
                    coverImageId = await UploadImage(values.coverImage);
                }
            }

            const populatedGalleryImages = [];
            try {
                if (galleryImages && galleryImages.length > 0) {
                    for (let i = 0; i < galleryImages.length; i++) {
                        if(galleryImages[i].imageId) {
                            populatedGalleryImages.push(galleryImages[i])
                        } else {
                            const gallerImageId = await UploadImage(galleryImages[i].original);
                            populatedGalleryImages.push({
                                imageId: gallerImageId,
                                original: galleryImages[i].original
                            })
                        }
                    }
                }
            } catch (error) {
                console.log('Error while uploading gallery images')
                console.log(error)
            }

            const businessItemPayload = {
                id: location.state.businessItemId,
                type: businessDataObj.type,
                name: values.name,
                description: values.description,
                ageCategories: values.ageCategory.map((ageCategory) => { return ageCategory.value }),
                additionals: values.additional.map((additional) => { return additional.value }),
                mediaPayload: {
                    mainImage: mainImageId,
                    coverImage: coverImageId,
                    gallery: populatedGalleryImages.map((img)=>img.imageId),
                    id: businessDataObj?.mediaPayload?.id,
                },
                addresses: values.addresses?.map((addr) => {
                    return {
                        addressLine: addr.addressLine,
                        addressNumber: addr.addressNumber,
                        city: addr.city,
                        country: getCountryValue(addr.country),
                        zipCode: addr.zipCode,
                    }
                }),
                gender: values.gender.value,
                skillLevels: values.skillLevel.map((skillLevel) => { return skillLevel.value }),
                sports: values.sport.map((sport) => { return sport.value }),
                pricelist: values.pricingList,
                contactData: {
                    id: businessDataObj?.contactData?.id,
                    ... (values.website && { website: values.website }),
                    ... (values.email && { email: values.email }),
                    ... (values.contactPhone && { contactPhone: values.contactPhone }),
                },
                socialNetworkAccounts: {
                    id: businessDataObj?.socialAccounts?.id,
                    instagramAccount: values.instagramAccount,
                    twitterAccount: values.twitterAccount,
                    facebookAccount: values.facebookAccount,
                    tiktokAccount: values.tiktokAccount,
                }
            }

            switch (initialValuesData?.type) {
                case BusinessItemTypes.EVENT:
                    businessItemPayload.eventPayload = {
                        id: businessDataObj?.eventPayload?.id,
                        dateStart: values.eventDateStart,
                        dateFinish: values.eventDateFinish,
                    }
                    break;
                case BusinessItemTypes.PERSONAL_COACH:
                    businessItemPayload.personalCoachPayload = {
                        id: businessDataObj?.personalCoachPayload?.id,
                        numberOfFreeTrainings: values.numberOfFreeTrainings,
                        yearOfBirth: values.yearOfBirth,
                        aboutMe: values.aboutMe,
                    }
                    break;
                case BusinessItemTypes.SPORT_FACILITY:
                    businessItemPayload.sportFacilityPayload = {
                        id: businessDataObj?.sportFacilityPayload?.id,
                        numberOfFreeTrainings: values.numberOfFreeTrainings,
                        workingHours: values.workingHours.map((workingHours) => {
                            const { day, ...other } = workingHours;
                            return {
                                day: day.value,
                                ...other
                            }
                        })
                    }
                    break;
                case BusinessItemTypes.GROUP_ACTIVITY:
                    businessItemPayload.groupActivityPayload = {
                        id: businessDataObj?.groupActivityPayload?.id,
                        schedule: values.schedule,
                        aboutPrograms: values.aboutPrograms,
                        numberOfFreeTrainings: values.numberOfFreeTrainings
                    }
                    break;
            }
            await CreatePostRequest(BackRequests.EDIT_BUSINESS_ITEM, businessItemPayload);
            openSuccessSB("Business data changed successfuly");
            // setInitialValuesData(values);
            setInitialValuesData((prev) => ({
                ...values,
                mediaImages: {
                  mainImage: values.mainImage,
                  coverImage: values.coverImage
                },
                mainImage: values.mainImage,
                coverImage: values.coverImage,
                galleryImages: populatedGalleryImages
              }));

            setBusinessDataObj((prev) => ({
                ...businessDataObj,
                mediaPayload: {
                  mainImage: mainImageId,
                  coverImage: coverImageId
                },
            }));
            setGalleryImages(populatedGalleryImages);
            // setShouldReset((shouldReset) => !shouldReset);
        } catch (error) {
            console.log(error)
            openErrorSB(GetErrorMessage(error));
            throw error;
        }
    };

    const selectImageAction = (image, type, values, setFieldValue) => {
        if (type == "main") {
            setFieldValue(formFields.mainImage.name, image);
            setShowMainImageError(false);
        }
        if (type == "cover") {
            setFieldValue(formFields.coverImage.name, image);
        }
    }

    const resetEditForm = (resetForm, values) => {

        resetForm();
        setShouldReset((shouldReset) => !shouldReset);
        values.additional = initialValuesData.additional;
        values.mediaImages = initialValuesData.mediaImages;
        values.mainImage = initialValuesData.mediaImages?.mainImage;
        values.coverImage = initialValuesData.mediaImages?.coverImage;
        setGalleryImages(initialValuesData.galleryImages);
        if (inputRef.current) {
            inputRef.current.value = ""; // Reset the input value
        }
    }

    // Gallery
    // Custom renderItem function for each gallery item
    const renderCustomItem = (item, index) => (
        <div>
            <img src={item.original} style={{ width: "60%" }} />
            <SoftBox textAlign="center" mt={1}>
                <Tooltip title={t('businessItem.editPage.removeImage')} placement="bottom" arrow>
                    <SoftBox>
                        <IconButton variant="gradient" color="primary" size="medium"
                            onClick={() => deleteGalleryImage(galleryImages.indexOf(item))}>
                            <DeleteIcon sx={{ fontWeight: "bold" }} fontSize="medium">{t('businessItem.editPage.deleteTooltip')}</DeleteIcon>                        </IconButton>
                    </SoftBox>
                </Tooltip>
            </SoftBox>
        </div>
    );

    const deleteGalleryImage = (index) => {
        const updatedImages = galleryImages.filter((_, i) => i !== index);
        setGalleryImages(updatedImages);
    };

    const handleAddImage = (event) => {
        setShowSizeError(false);
        setImageTypeError(false);
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];
        if (event.target.files && event.target.files.length > 0) {
            const uploadedImages = [...galleryImages];
            for (let i = 0; i < event.target.files.length; i++) {
                const file = event.target.files[i];
                if (file.size > maxSize) {
                    setShowSizeError(true);
                    return;
                }

                if (!allowedTypes.includes(file.type)) {
                    setImageTypeError(true);
                    return;
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    uploadedImages.push({
                        original: reader.result
                    });
                    setGalleryImages([...uploadedImages]);
                }
            }
        }
    }

    const getEditElements = () => {
        return (<SoftBox pt={3} pb={8} flex="1" display="flex" sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
            p: 3,
            position: "relative",
        })}>
            {!showLoader &&

                <SoftBox>
                    <Formik
                        enableReinitialize
                        initialValues={_.cloneDeep(initialValuesData)}
                        validationSchema={validations}
                        onSubmit={handleSubmit}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue, handleChange, resetForm }) => (
                            <Form id="edit-business-data" autoComplete="off">
                                <SoftBox mb={3}>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={3} >
                                            <SoftTypography variant="h4" fontWeight="medium">
                                                {t('businessItem.editPage.title')}
                                            </SoftTypography>
                                            <SoftBox mt={1} mb={2}>
                                                <SoftTypography variant="body2" color="text">
                                                    {t('businessItem.editPage.description')}
                                                </SoftTypography>
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </SoftBox>
                                <SoftBox my={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={8}>
                                            <Card sx={{ height: "100%" }}>
                                                <BusinessInfoEdit setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} type={initialValuesData?.type} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Card sx={{ height: "100%" }}>
                                                <ContactDataEdit values={values} errors={errors} touched={touched} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={isAddressBoxFullWidth ? 12 : 7}>
                                            <Card sx={{ height: "100%" }}>
                                                {initialValuesData?.type &&
                                                    <AddressEdit values={values} errors={errors} touched={touched} type={initialValuesData?.type} />
                                                }
                                            </Card>
                                        </Grid>
                                        {initialValuesData?.type && (initialValuesData?.type == BusinessItemTypes.SPORT_FACILITY || initialValuesData?.type == BusinessItemTypes.GROUP_ACTIVITY) && (
                                            <Grid item xs={12} lg={5}>
                                                <Card sx={{ height: "100%" }}>
                                                    <WorkingHoursAndScheduleEdit values={values} errors={errors} touched={touched} type={initialValuesData?.type} setFieldValue={setFieldValue} />
                                                </Card>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} lg={6}>
                                            <Card sx={{ height: "100%" }}>
                                                <ProgramDetailsEdit values={values} errors={errors} touched={touched} type={initialValuesData?.type} setFieldValue={setFieldValue} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Card sx={{ height: "100%" }}>
                                                {initialValuesData?.type &&
                                                    <SportFacilityAdditionalEdit values={values} errors={errors} touched={touched} type={initialValuesData?.type} setFieldValue={setFieldValue} />
                                                }
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <Card sx={{ height: "100%" }}>
                                                <PricingEdit values={values} errors={errors} touched={touched} handleChange={handleChange} setFieldValue={setFieldValue} />
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Card sx={{ height: "100%" }}>
                                                <ImageEdit initialImage={initialValuesData.mediaImages?.mainImage} type="main" showTitle selectImageAction={selectImageAction} values={values} showMainImageError={showMainImageError} shouldReset={shouldReset} setFieldValue={setFieldValue}/>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Card sx={{ height: "100%" }}>
                                                <ImageEdit initialImage={initialValuesData.mediaImages?.coverImage} type="cover" showTitle selectImageAction={selectImageAction} values={values} shouldReset={shouldReset} setFieldValue={setFieldValue}/>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Card sx={{ height: "100%", padding: "24px" }} p={3} >
                                                <SoftBox height="100%" display="grid">
                                                    <SoftBox mb={2} ml={0.5} lineHeight={0} display="inline-block">
                                                        <SoftTypography variant="h5" component="label">
                                                            {t('businessItem.editPage.gallery')}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    {galleryImages && galleryImages?.length > 0 ? (
                                                        <SoftBox>
                                                            <Gallery
                                                                items={galleryImages}
                                                                renderItem={(item, index) => renderCustomItem(item, index)} // Using renderItem to customize each image
                                                                showFullscreenButton={false}
                                                                showThumbnails={false}
                                                                autoPlay={false}
                                                                showPlayButton={false}
                                                                showBullets={false}
                                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                            />
                                                            {showSizeError && <SoftTypography color="error" variant="body2">{t('uploadImage.imageSizeError')}</SoftTypography>}
                                                            {showImageTypeError && <SoftTypography color="error" variant="body2">{t('uploadImage.imageTypeError')}</SoftTypography>}
                                                        </SoftBox>
                                                    ) : (
                                                        <SoftBox display="flex" justifyContent="center">
                                                            <SoftBox width="30%" display="flex" alignSelf="center" flexDirection="column">
                                                                <img src={galleryPlaceholder} width="100%"></img>
                                                            </SoftBox>
                                                            {showSizeError && <SoftTypography color="error" variant="body2">{t('uploadImage.imageSizeError')}</SoftTypography>}
                                                            {showImageTypeError && <SoftTypography color="error" variant="body2">{t('uploadImage.imageTypeError')}</SoftTypography>}
                                                        </SoftBox>
                                                    )}
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg, image/png, image/webp"
                                                        ref={inputRef}
                                                        onChange={handleAddImage}
                                                        style={{ display: "none" }}
                                                        multiple
                                                    />
                                                    <SoftBox style={{ marginTop: "auto" }} width="100%" display="flex" justifyContent="end">
                                                        <Tooltip title={t('buttonLabels.addImage')} placement="bottom" arrow>
                                                            <SoftButton color="secondary" size="small"
                                                                onClick={onChooseImg}
                                                            >
                                                                {t('buttonLabels.plusAdd')}
                                                            </SoftButton>
                                                        </Tooltip>
                                                    </SoftBox>
                                                </SoftBox>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                                <SoftBox mb={3}>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={3} >
                                            <SoftBox display="flex" justifyContent="end">
                                                <SoftBox mr={2}>
                                                    <SoftButton key="notsubmit" variant="gradient" color="dark" type="button" onClick={() => resetEditForm(resetForm, values)}> {t('businessItem.editPage.cancel')} </SoftButton>
                                                </SoftBox>
                                                <SoftButton key="submit" color="secondary" type="submit" disabled={isSubmitting || Object.keys(errors).length != 0}>
                                                    {t('businessItem.editPage.button')}
                                                </SoftButton>
                                            </SoftBox>
                                            <SoftBox display="flex" justifyContent="flex-end" mt={2}>
                                                {Object.keys(errors).length != 0 && (
                                                    <SoftBox>
                                                        <SoftTypography variant="caption" color="error" align="right">
                                                            {t('businessItem.editPage.validationError')}
                                                        </SoftTypography>
                                                        <List>
                                                            {Object.entries(errors).map(([field, error]) => {
                                                                return (
                                                                    <ListItem variant="caption" color="error" align="right" key={field}>
                                                                        <SoftTypography variant="caption" color="error" align="right">{`${field}: ${error}`}</SoftTypography>
                                                                    </ListItem>
                                                                )
                                                            }
                                                            )}
                                                        </List>
                                                    </SoftBox>
                                                )}
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </SoftBox>
                            </Form>
                        )}
                    </Formik>
                </SoftBox>
            }
            {showLoader &&
                <SoftBox textAlign="center" sx={{ margin: "auto", height: "100%" }}>
                    <img src={loader} alt="Loading..." className="loader" />
                </SoftBox>}
            {SuccessMessageSnackBar({ title: "Success", successMessage, successSB, closeSuccessSB })}
            {ErrorMessageSnackBar({ title: "Error", errorMessage, errorSB, closeErrorSB })}
        </SoftBox>)
    }

    if (location.state.isAdmin) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                {getEditElements()}
                <AdminFooter />
            </DashboardLayout>
        )
    } else {
        return (
            <PageLayout>
                <SoftBox align="center">
                    <HeaderMenu />
                </SoftBox>
                {getEditElements()}
                <Footer />
            </PageLayout>
        )
    }
}

export default EditBusiness;
