/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import AnnouncementCard from "examples/Cards/AnnouncementCard";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function PricelistTabMob({ pricelist }) {
    const { t, i18n } = useTranslation();

    const dataTableData = {
        columns: [
            { Header: t('tabs.tab_price_desc'), accessor: "description", width: "49%" },
            { Header: t('tabs.tab_price_reg'), accessor: "regularPrice", width: "17%", align: "center" },
            { Header: t('tabs.tab_price_disc'), accessor: "moveOnDiscount", width: "17%", align: "center" },
            { Header: t('tabs.tab_price_new'), accessor: "moveOnPrice", width: "17%", align: "center" },
        ],
        rows: [],
    };
    const [tableData, setTableData] = useState(dataTableData);
    const options = { maximumFractionDigits: 2 }
    /*useEffect(() => {
        const rows = pricelist.map((item) => {
            return {
                description: (
                    <SoftBox display="inline-block" sx={{ verticalAlign: "middle", wordbreak: "break-all" }}>
                        <SoftTypography display="block" variant="body2"> {item.description}</SoftTypography>
                    </SoftBox>
                ),
                regularPrice: (
                    <SoftTypography textTransform="uppercase" variant="body2"> {Intl.NumberFormat("en-US", options).format(item.price) + " " + item.currency}</SoftTypography>
                ),
                moveOnDiscount: (
                    <SoftBadge container variant="contained" color="secondary" badgeContent={item.discount + "%"} />
                ),
                moveOnPrice: (
                    <SoftTypography color="secondary" variant="body2" fontWeight="bold" textTransform="uppercase"> {Intl.NumberFormat("en-US", options).format(item.discountedPrice) + " " + item.currency}</SoftTypography>
                ),
            }
        })
        setTableData({
            columns: dataTableData.columns,
            rows
        });
    }, []);*/

    return (
        <SoftBox>
            <Card>
                <SoftBox p={0}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={12} lg={12}>
                            {pricelist.map((item, index) =>
                                <div key={index} style={{ margin: 5 }}><Grid item xs={12} lg={4}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Card>
                                                    <SoftBox pt={0.5} pb={1} px={1}>
                                                        <SoftTypography variant="h6">{t('tabs.tab_price_desc')}</SoftTypography>
                                                        <SoftBox mt={1} mb={2} lineHeight={0}>
                                                            <SoftTypography variant="button" fontWeight="regular" color="text">
                                                                {item.description}
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftBox
                                                            bgColor="grey-100"
                                                            justifyContent="space-between"
                                                            alignItems="left"
                                                            borderRadius="lg"
                                                            p={1}
                                                            >
                                                            <SoftTypography variant="button" fontWeight="regular" color="text" style={{ width:"100%"}}>
                                                                <p>{t('tabs.tab_price_reg')} <b>{Intl.NumberFormat("en-US", options).format(item.price) + " " + item.currency} </b></p>
                                                            </SoftTypography>
                                                            <SoftTypography variant="button" fontWeight="regular" color="text" style={{ width:"100%"}}>
                                                                <p>{t('tabs.tab_price_disc')} <span style={{ color:"#fb9b50", fontSize:"20px"}} >{item.discount + "%"}</span></p>
                                                            </SoftTypography><br></br>
                                                            <SoftTypography variant="button" align="center" fontWeight="regular" color="text" style={{ width:"100%"}}>
                                                                <p> <SoftTypography color="secondary" variant="body2" textTransform="uppercase">{t('tabs.tab_price_new')}: <SoftBadge container variant="contained" color="secondary" badgeContent={Intl.NumberFormat("en-US", options).format(item.discountedPrice) + " " + item.currency} /></SoftTypography></p>
                                                            </SoftTypography>
                                                        </SoftBox>
                                                    </SoftBox>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </SoftBox>
            </Card>
        </SoftBox>
    )
}

export default PricelistTabMob;

