/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
// react-quill components
import ReactQuill from "react-quill";

// react-quill styles
import "react-quill/dist/quill.snow.css";

// Custom styles for the SoftEditor
import SoftEditorRoot from "components/SoftEditor/SoftEditorRoot";
import { useEffect, useRef } from "react";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import { ErrorMessage } from "formik";

function SoftEditor(props, fieldName) {
  const quillRef = useRef(null);

  useEffect(() => {
    const targetNode = quillRef.current?.getEditor()?.root;
    if (targetNode) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            console.log("Child nodes have changed");
          }
        }
      });

      observer.observe(targetNode, { childList: true, subtree: true });

      return () => observer.disconnect(); // Clean up on unmount
    }
  }, []);

  const modules = {
    toolbar: [
      [{header: [1, 2, 3, 4, false]}],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      [{ script: "sub" }, { script: "super" }],
      ['clean']
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "background",
    "code",
    "script",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    'align',
  ];

  return (
    <SoftEditorRoot>
      <ReactQuill ref={quillRef} theme="snow" modules={modules} formats={formats} {...props}/>
      <SoftBox mt={0.75}>
        <SoftTypography component="div" variant="caption" color="error">
          <ErrorMessage name={fieldName}/>
        </SoftTypography>
      </SoftBox>
    </SoftEditorRoot>
  );
}

export default SoftEditor;
