/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {BusinessItemTypes} from "helpers/enums/business-item.enum";
import {countryOptions} from "layouts/pages/business/business-form-helpers";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum"
import {dayOptions, genderOptions} from "../../business-form-helpers";

const businessItemOptions = [
    {value: BusinessItemTypes.PERSONAL_COACH, label: "Personal Coach"},
    {value: BusinessItemTypes.SPORT_FACILITY, label: "Sport Facility"},
    {value: BusinessItemTypes.GROUP_ACTIVITY, label: "Group Activity"},
    {value: BusinessItemTypes.EVENT, label: "Event"},
];

// [CHANGE COUNTRY TO LIST] Expand the list
const currencyOptions = [
    {value: "rsd", label: "RSD"},
    // { value: "btc", label: "BTC" },
    // { value: "cny", label: "CNY" },
    // { value: "eur", label: "EUR" },
    // { value: "gbp", label: "GBP" },
    // { value: "inr", label: "INR" },
    // { value: "use", label: "USD" },
];

const formDataFields = (t) => {
    return {
        [FormNames.CHOOSE_BUSINESS_TYPE]: {
            type: {
                name: "type",
                default: businessItemOptions[0].value,
            },
        },
        [FormNames.BUSINESS_INFO_FORM]: {
            name: {
                name: "name",
                label: t('form.businessInfo.nameMandatory'),
                labelPersonalCoach: t('form.businessInfo.firstAndLastNameMandatory'),
                type: "text",
                placeholder: t('form.businessInfo.name'),
                placeholderPersonalCoach: t('form.businessInfo.firstAndLastNamePlaceholder'),
                errorMsg: t('form.businessInfo.firstAndLastNameRequiredErrorMsg'),
            },
            description: {
                name: "description",
                label: t('form.businessInfo.description'),
                placeholder: t('form.businessInfo.aboutBusiness'),
            },
            eventDateStart: {
                name: "eventDateStart",
                label: t('form.businessInfo.dateStartMandatory'),
                type: "date",
                errorMsg: t('form.businessInfo.dateStartRequiredErrorMsg'),
            },
            eventDateFinish: {
                name: "eventDateFinish",
                label: t('form.businessInfo.dateFinishMandatory'),
                type: "date",
                errorMsg: t('form.businessInfo.dateFinishRequiredErrorMsg'),
                errorMsgInvalid: t('form.businessInfo.dateFinishInvalidErrorMsg'),
            },
            yearOfBirth: {
                name: "yearOfBirth",
                label: t('form.businessInfo.yearOfBirth'),
                type: "number",
                errorMsg: t('form.businessInfo.yearOfBirthMinErrorMsg'),
            },
            aboutMe: {
                name: "aboutMe",
                label: t('form.businessInfo.aboutMeMandatory'),
                placeholder: t('form.businessInfo.aboutMePlaceholder'),
                errorMsg: t('form.businessInfo.aboutMeRequiredErrorMsg')
            },
            aboutPrograms: {
                name: "aboutPrograms",
                label: t('form.businessInfo.aboutProgramsMandatory'),
                placeholder: t('form.businessInfo.aboutProgramsPlaceholder'),
                errorMsg: t('form.businessInfo.aboutProgramsRequiredErrorMsg')
            }
        },
        [FormNames.PROGRAM_DETAILS_FORM]: {
            ageCategory: {
                name: "ageCategory",
                label: t('form.programDetails.ageCategory'),
            },
            gender: {
                name: "gender",
                label: t('form.programDetails.gender'),
                options: genderOptions,
                default: genderOptions[0],
            },
            skillLevel: {
                name: "skillLevel",
                label: t('form.programDetails.skillLevel'),
            },
            sport: {
                name: "sport",
                label: t('form.programDetails.sport'),
            },
            numberOfFreeTrainings: {
                name: "numberOfFreeTrainings",
                label: t('form.programDetails.numberOfFreeTrainings'),
                type: "number",
                placeholder: "0",
                errorMsg: t('form.programDetails.numberOfFreeTrainingsInvalidErrorMsg'),
            },
            workingHours: {
                name: "workingHours",
                errorMsg: t('form.programDetails.workingHoursRequiredErrorMsg'),
                minimumErrorMsg: t('form.programDetails.workingHoursMinOneErrorMsg'),
                props: {
                    day: {
                        name: "day",
                        label: t('form.programDetails.dayMandatory'),
                        options: dayOptions,
                        default: dayOptions[0],
                        errorMsg: t('form.programDetails.dayRequiredErrorMsg'),
                    },
                    startTime: {
                        name: "startTime",
                        label: t('form.programDetails.startTimeMandatory'),
                        type: "time",
                        errorMsg: t('form.programDetails.startTimeRequiredErrorMsg'),
                    },
                    endTime: {
                        name: "endTime",
                        label: t('form.programDetails.endTimeMandatory'),
                        type: "time",
                        errorMsg: t('form.programDetails.endTimeRequiredErrorMsg'),
                        minErrorMsg: t('form.programDetails.endTimeInvalidErrorMsg'),
                    },
                }
            },
            schedule: {
                name: "schedule",
                errorMsg: t('form.programDetails.scheduleRequiredErrorMsg'),
                minimumErrorMsg: t('form.programDetails.scheduleMinOneErrorMsg'),
                props: {
                    description: {
                        name: "description",
                        label: t('form.programDetails.descriptionMandatory'),
                        type: "text",
                        errorMsg: t('form.programDetails.descriptionRequiredErrorMsg'),
                    },
                    startTime: {
                        name: "startTime",
                        label: t('form.programDetails.startTimeMandatory'),
                        type: "time",
                        errorMsg: t('form.programDetails.startTimeRequiredErrorMsg'),
                    },
                    endTime: {
                        name: "endTime",
                        label: t('form.programDetails.endTimeMandatory'),
                        type: "time",
                        errorMsg: t('form.programDetails.endTimeRequiredErrorMsg'),
                        minErrorMsg: t('form.programDetails.endTimeInvalidErrorMsg'),
                    },
                }
            }
        },
        [FormNames.ADDRESS_FORM]: {
            addresses: {
                name: "addresses",
                minimumErrorMsg: t('form.address.addressMinErrorMsg'),
                props: {
                    addressLine: {
                        name: "addressLine",
                        label: t('form.address.addressLineMandatory'),
                        type: "text",
                        errorMsg: t('form.address.addressLineRequiredErrorMsg'),
                    },
                    addressNumber: {
                        name: "addressNumber",
                        label: t('form.address.addressNumberMandatory'),
                        type: "text",
                        errorMsg: t('form.address.addressNumberRequiredErrorMsg'),
                    },
                    city: {
                        name: "city",
                        label: t('form.address.cityMandatory'),
                        type: "text",
                        errorMsg: t('form.address.cityRequiredErrorMsg'),
                    },
                    country: {
                        name: "country",
                        label: t('form.address.countryMandatory'),
                        options: countryOptions,
                        default: countryOptions[0].label, // [CHANGE COUNTRY TO LIST] countryOptions[0]
                        errorMsg: t('form.address.countryRequiredErrorMsg'),
                    },
                    zipCode: {
                        name: "zipCode",
                        label: t('form.address.zipCodeMandatory'),
                        type: "text",
                        errorMsg: t('form.address.zipCodeRequiredErrorMsg'),
                        errorMsgInvalid: t('form.address.zipCodeInvalidErrorMsg'),
                    },
                }
            },
        },
        [FormNames.SPORT_FACILITY_ADDITIONAL_FORM]: {
            additional: {
                name: "additional",
            },
        },
        [FormNames.MEDIA_FORM]: {
            mainImage: {
                name: "mainImage",
                accept: "image/*",
                errorMsg: t('form.media.mainImageRequiredErrorMsg'),
            },
            coverImage: {
                name: "coverImage",
                accept: "image/*"
            },
            galleryImages: {
                name: "galleryImages",
                accept: "image/*"
            }
        },
        [FormNames.CONTACT_DATA_FORM]: {
            website: {
                name: "website",
                label: t('form.contactData.website'),
                type: "text",
                placeholder: "",
                errorMsg: t('form.contactData.websiteInvalidErrorMsg'),
            },
            email: {
                name: "email",
                label: t('form.contactData.email'),
                type: "email",
                placeholder: "",
                errorMsg: t('form.contactData.emailInvalidErrorMsg'),
            },
            contactPhone: {
                name: "contactPhone",
                label: t('form.contactData.phone'),
                type: "text",
                placeholder: "+000 00 000000",
                invalidMsg: t('form.contactData.phoneInvalidErrorMsg')
            },
            instagramAccount: {
                name: "instagramAccount",
                label: t('form.contactData.instagram'),
                type: "text",
                placeholder: "https://...",
                errorMsg: t('form.contactData.instagramInvalidErrorMsg'),
            },
            facebookAccount: {
                name: "facebookAccount",
                label: t('form.contactData.facebook'),
                type: "text",
                placeholder: "https://...",
                errorMsg: t('form.contactData.facebookInvalidErrorMsg'),
            },
            twitterAccount: {
                name: "twitterAccount",
                label: t('form.contactData.twitterX'),
                type: "text",
                placeholder: "https://...",
                errorMsg: t('form.contactData.twitterXInvalidErrorMsg'),
            },
            tiktokAccount: {
                name: "tiktokAccount",
                label: t('form.contactData.tikTok'),
                type: "text",
                placeholder: "https://...",
                errorMsg: t('form.contactData.tikTokInvalidErrorMsg'),
            },
        },
        [FormNames.PRICING_FORM]: {
            pricingList: {
                name: "pricingList",
                errorMsg: t('form.pricing.pricingError'),
                minimumErrorMsg: t('form.pricing.pricingMinOneError'),
                props: {
                    description: {
                        name: "description",
                        label: t('form.pricing.descriptionMandatory'),
                        type: "text",
                        placeholder: t('form.pricing.descriptionPlaceholder'),
                        errorMsg: t('form.pricing.descriptionRequiredErrorMsg'),
                    },
                    price: {
                        name: "price",
                        label: t('form.pricing.priceMandatory'),
                        type: "number",
                        errorMsg: t('form.pricing.priceRequiredErrorMsg'),
                        minimumErrorMsg: t('form.pricing.priceRequiredErrorMsg')
                    },
                    currency: {
                        name: "currency",
                        label: t('form.pricing.currencyMandatory'),
                        options: currencyOptions,
                        default: currencyOptions[0].label // [CHANGE CURRENCY TO LIST] currencyOptions[0]
                    },
                    discount: {
                        name: "discount",
                        label: t('form.pricing.discountMandatory'),
                        type: "number",
                        errorMsg: t('form.pricing.discountRequiredErrorMsg'),
                        minimumErrorMsg: t('form.pricing.discountMinInvalidErrorMsg') // TODO
                    },
                    discountedPrice: {
                        name: "discountedPrice",
                        label: t('form.pricing.moveOnPriceMandatory'),
                        type: "text",
                    }
                }
            }
        },
    }
}

// [CHANGE CURRENCY TO LIST] remove this
const getCurrencyValue = (label) => {
    return currencyOptions.find((country) => country.label == label).value
};


export {
    formDataFields,
    getCurrencyValue
};
 