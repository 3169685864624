/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";
// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Footer from "layouts/pages/home/components/Footer";
import PersonalTrainerContent from "layouts/pages/home/components/PersonalTrainerContent";
import GroupActivitiesContent from "layouts/pages/home/components/GroupActivitiesContent";
import EventContent from "layouts/pages/home/components/EventContent";
import GymContent from "layouts/pages/home/components/GymContent";
import InfoContent from "layouts/pages/home/components/InfoContent";
import InstructionsBanner from "layouts/pages/home/components/InstructionsBanner";
import Faq from "layouts/pages/home/components/Faq";
import image2 from "assets/images/moveon/banner2.jpg";

import { Parallax } from 'react-parallax';
import PublicHeader from "./components/PublicHeader";

// Helpers
import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";
import UserRoles from "helpers/enums/user-role.enum";
import { useNavigate } from "react-router-dom";
import RoutesNames from "helpers/enums/routes-map.enum";


function HomePage() {
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["59", "89", "99"]);
  const navigate = useNavigate();

  const [sponsoredEvents, setSponsoredEvents] = useState([]);
  const [sponsoredSportFacilities, setSponsoredSportFacilities] = useState([]);
  const [sponsoredPersonalCoaches, setSponsoredPersonalCoaches] = useState([]);
  const [sponsoredGroupActivities, setSponsoredGroupActivities] = useState([]);


  const getSponsoredBusinesses = async () => {
    try {
      const sponsoredBusinesses = {};
      const responseEvents = await CreateGetRequest(BackRequests.GET_SPONSORED_BUSINESSES_ALL_TYPES);
      sponsoredBusinesses["sponsoredEvents"] = responseEvents.data.data;

      return responseEvents.data.data;
    } catch (error) {
      /*console.log("error")
      console.log(error)
      // TODO: Sta na stranici prikazati ako se ne ucitaju podaci
      openErrorSB(setErrorMessage(error), true);*/
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    console.log(userData)
    if (userData?.role === UserRoles.SYSTEM_ADMIN) {
      navigate(RoutesNames.ADMIN_CRM);
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const sponsoredBusinesses = await getSponsoredBusinesses();

      if (sponsoredBusinesses) {
        // Get media
        for (let i = 0; i < sponsoredBusinesses.sponsoredEvents.length; i++) {
          const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + sponsoredBusinesses.sponsoredEvents[i].mediaPayload.mainImage, { responseType: 'blob' });
          sponsoredBusinesses.sponsoredEvents[i].mediaPayload.mainImageUrl = URL.createObjectURL(imageResponse.data);
        }
        setSponsoredEvents(sponsoredBusinesses.sponsoredEvents);

        for (let i = 0; i < sponsoredBusinesses.sponsoredSportFacilities.length; i++) {
          const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + sponsoredBusinesses.sponsoredSportFacilities[i].mediaPayload.mainImage, { responseType: 'blob' });
          sponsoredBusinesses.sponsoredSportFacilities[i].mediaPayload.mainImageUrl = URL.createObjectURL(imageResponse.data);
        }
        setSponsoredSportFacilities(sponsoredBusinesses.sponsoredSportFacilities);

        for (let i = 0; i < sponsoredBusinesses.sponsoredPersonalCoaches.length; i++) {
          const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + sponsoredBusinesses.sponsoredPersonalCoaches[i].mediaPayload.mainImage, { responseType: 'blob' });
          sponsoredBusinesses.sponsoredPersonalCoaches[i].mediaPayload.mainImageUrl = URL.createObjectURL(imageResponse.data);
        }
        setSponsoredPersonalCoaches(sponsoredBusinesses.sponsoredPersonalCoaches);

        for (let i = 0; i < sponsoredBusinesses.sponsoredGroupActivities.length; i++) {
          const imageResponse = await CreateGetRequest(BackRequests.GET_MEDIA + sponsoredBusinesses.sponsoredGroupActivities[i].mediaPayload.mainImage, { responseType: 'blob' });
          sponsoredBusinesses.sponsoredGroupActivities[i].mediaPayload.mainImageUrl = URL.createObjectURL(imageResponse.data);
        }
        setSponsoredGroupActivities(sponsoredBusinesses.sponsoredGroupActivities);
      }
    }
    loadData();
  }, []);


  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "annual") {
      setPrices(["119", "159", "399"]);
    } else {
      setPrices(["59", "89", "99"]);
    }
  };

  return (
    <PageLayout>
      <SoftBox align="center">
        <PublicHeader />
      </SoftBox>
      <SoftBox align="center">
        <InfoContent />
        <GroupActivitiesContent sponsoredGroupActivities={sponsoredGroupActivities} />
        <PersonalTrainerContent sponsoredPersonalCoaches={sponsoredPersonalCoaches} />
        <InstructionsBanner />
        <GymContent sponsoredSportFacilities={sponsoredSportFacilities} />
        <SoftBox padding="50px 0 10px 0">
          <Parallax
            blur={{ min: -15, max: 15 }}
            bgImage={image2}
            strength={-200}
          >
            <div style={{ height: '300px' }} >
              <Container>
                {// <CounterContent />
                }
              </Container>
            </div>
          </Parallax>
        </SoftBox>
        <EventContent sponsoredEvents={sponsoredEvents} />
      </SoftBox>
      <Container>
        <Faq />
      </Container>
      <SoftBox mt={8}>
        <Footer />
      </SoftBox>
    </PageLayout>
  );
}

export default HomePage;
