/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import { React } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Helpers
import { BusinessItemTypes } from "helpers/enums/business-item.enum";
import typography from "assets/theme/base/typography";
import {useTranslation} from "react-i18next";

function ProductInfo({ businessType, businessDescription, personalCoachPayload }) {
  const { t, i18n } = useTranslation();

  return (
    (() => {
      if (businessType === BusinessItemTypes.PERSONAL_COACH) {
        return (
          <div>
            <SoftBox>
              {businessDescription && (
                <SoftBox>
                    <div className="post__content" style={typography.body2}>
                      <div
                        dangerouslySetInnerHTML={{ __html: businessDescription }}
                      />
                      <style>
                        {`
                          .post__content ul, .post__content ol {
                              margin-left: 20px;
                              padding-left: 20px;
                          }
                        `}
                      </style>
                    </div>
                </SoftBox>
              )}
              { personalCoachPayload &&
                (<SoftBox>
                  <SoftTypography variant="h6" mb={2}>
                    {t('tabs.tab_about_me')}
                  </SoftTypography>
                  <SoftTypography variant="body2">{t('tabs.tab_year_of_birth')}:&nbsp; {personalCoachPayload?.yearOfBirth} </SoftTypography>
                    <div className="post__content" style={typography.body2}>
                      <div
                        dangerouslySetInnerHTML={{ __html: personalCoachPayload?.aboutMe }}
                      />
                      <style>
                        {`
                          .post__content ul, .post__content ol {
                              margin-left: 20px;
                              padding-left: 20px;
                          }
                        `}
                      </style>
                    </div>
                </SoftBox>)
              }
            </SoftBox>
          </div>)
      } else if (businessType === BusinessItemTypes.GROUP_ACTIVITY) {
        return (
          <div className="post__content" style={typography.body2}>
            <div
              dangerouslySetInnerHTML={{ __html: businessDescription }}
            />
            <style>
              {`
                    .post__content ul, .post__content ol {
                        margin-left: 20px;
                        padding-left: 20px;
                    }
              `}
            </style>
          </div>
        )
      }
      else {
        return (
        <SoftBox>
            <div className="post__content" style={typography.body2}>
              <div
                dangerouslySetInnerHTML={{ __html: businessDescription }}
              />
              <style>
                {`
                    .post__content ul, .post__content ol {
                      margin-left: 20px;
                      padding-left: 20px;
                    }
                `}
              </style>
            </div>
        </SoftBox>)
      }
    })()
  );
}

export default ProductInfo;
