/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const reservationStatusOptions = [
  { value: "created", label: "reservations.status.created", selected: true  },
  { value: "realized", label: "reservations.status.used" },
  { value: "canceled", label: "reservations.status.canceled" },
];

const formFields = {
  reservationId: {
    name: "reservationId",
    label: "businessProfile.businessReservations.searchForm.reservationId",
    type: "text",
  },
  dateFrom: {
    name: "dateFrom",
    label: "businessProfile.businessReservations.searchForm.dateFrom",
    type: "date",
  },
  dateTo: {
    name: "dateTo",
    label: "businessProfile.businessReservations.searchForm.dateTo",
    type: "date",
  },
  name: {
    name: "name",
    label: "businessProfile.businessReservations.searchForm.name",
    type: "text",
    errorMessage: "Name and Last name must be populated together"
  },
  lastname: {
    name: "lastname",
    label: "businessProfile.businessReservations.searchForm.lastname",
    type: "text",
    errorMessage: "Name and Last name must be populated together"
  },
  email: {
    name: "email",
    label: "businessProfile.businessReservations.searchForm.email",
    type: "email",
    errorMessage: "Email address is invalid"
  },
  businessName: {
    name: "businessName",
    label: "businessProfile.businessReservations.searchForm.businessName",
    type: "select",
  },
  reservationStatus: {
    name: "reservationStatus",
    label: "businessProfile.businessReservations.searchForm.reservationStatus",
    type: "select",
    options: reservationStatusOptions
  }
};

export {
  formFields
};
