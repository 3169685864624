/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import {Form, Formik} from "formik";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import ButtonsSection from "layouts/pages/business/new-business/components/ButtonsSection";

import sportFacilityOption from "assets/images/sport-facility-option.png";
import personalCoachOption from "assets/images/personal-coach-option.png";
import sportEventOption from "assets/images/event-option.png";
import sportActivityOption from "assets/images/sport-activity-option.png"

import { BusinessItemTypes } from "helpers/enums/business-item.enum";

// Form schemas
import {formDataFields} from "layouts/pages/business/new-business/schemas/form";
import getInitialValues from "layouts/pages/business/new-business/schemas/initialValues";
import FormNames from "layouts/pages/business/new-business/schemas/form-names.enum";
import {useTranslation} from "react-i18next";

function ChooseType({activeStep, isLastStep, handleNext, handleBack, type}) {
  const { t, i18n } = useTranslation();
  const [selectedTypeValue, setSelectedTypeValue] = useState(BusinessItemTypes.PERSONAL_COACH);
  const initial = getInitialValues(t).initial;

  // Form data
  const formFields = formDataFields(t)[FormNames.CHOOSE_BUSINESS_TYPE];
  const initialValues = initial[FormNames.CHOOSE_BUSINESS_TYPE];

    const handleSubmit = async (values, actions) => {
        try {
            console.log("ChooseType HANDLE SUBMIT")
            console.log(selectedTypeValue)
            console.log(values)

            handleNext({
                type: values.type
            })
        } catch (error) {
            // TODO
            // openErrorSB(GetErrorMessage(error));
        }
    };

    const styles = {
        selectedTextOption: {
            fontSize: "17px",
            fontWeight: "800",
        },
        selectedImageOption: {
            transform: "scale(1.1)"
        }
    };

    const setSelectedValue = async (values, selectedOption, setFieldValue) => {
        setSelectedTypeValue(selectedOption)
        setFieldValue(formFields.type.name, selectedOption);
    };

    useEffect(() => {
        setSelectedTypeValue(type)
      },[]);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={FormNames.CHOOSE_BUSINESS_TYPE} autoComplete="off">
                    <SoftBox>
                        <SoftTypography variant="h5">
                            {t('createBusiness.steps.chooseBusinessType')}
                        </SoftTypography>
                        <SoftBox mt={2}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={3} textAlign="center">
                                    <SoftBox display="flex" alignItems="center" justifyContent="center" mt={3} sx={{ cursor: "pointer" }}
                                        onClick={() => setSelectedValue(values, BusinessItemTypes.SPORT_FACILITY, setFieldValue)}>
                                        <SoftAvatar src={sportFacilityOption} size="xxl" variant="rounded"
                                            style={selectedTypeValue == BusinessItemTypes.SPORT_FACILITY ? styles.selectedImageOption : null}
                                        />
                                    </SoftBox>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => setSelectedValue(values, BusinessItemTypes.SPORT_FACILITY, setFieldValue)}
                                        style={selectedTypeValue == BusinessItemTypes.SPORT_FACILITY ? styles.selectedTextOption : null}
                                    >
                                        {t('createBusiness.steps.sportsFacility')}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={3} textAlign="center">
                                    <SoftBox display="flex" alignItems="center" justifyContent="center" mt={3} sx={{ cursor: "pointer" }}
                                        onClick={() => setSelectedValue(values, BusinessItemTypes.PERSONAL_COACH, setFieldValue)}>
                                        <SoftAvatar src={personalCoachOption} size="xxl" variant="rounded"
                                            style={selectedTypeValue == BusinessItemTypes.PERSONAL_COACH ? styles.selectedImageOption : null}
                                        />
                                    </SoftBox>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => setSelectedValue(values, BusinessItemTypes.PERSONAL_COACH, setFieldValue)}
                                        style={selectedTypeValue == BusinessItemTypes.PERSONAL_COACH ? styles.selectedTextOption : null}
                                    >
                                        {t('createBusiness.steps.personalCoach')}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={3} textAlign="center">
                                    <SoftBox display="flex" alignItems="center" justifyContent="center" mt={3} sx={{ cursor: "pointer" }}
                                        onClick={() => setSelectedValue(values, BusinessItemTypes.GROUP_ACTIVITY, setFieldValue)}>
                                        <SoftAvatar src={sportActivityOption} size="xxl" variant="rounded"
                                            style={selectedTypeValue == BusinessItemTypes.GROUP_ACTIVITY ? styles.selectedImageOption : null}
                                        />
                                    </SoftBox>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => setSelectedValue(values, BusinessItemTypes.GROUP_ACTIVITY, setFieldValue)}
                                        style={selectedTypeValue == BusinessItemTypes.GROUP_ACTIVITY ? styles.selectedTextOption : null}
                                    >
                                        {t('createBusiness.steps.groupActivity')}
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={3} textAlign="center">
                                    <SoftBox display="flex" alignItems="center" justifyContent="center" mt={3} sx={{ cursor: "pointer" }}
                                        onClick={() => setSelectedValue(values, BusinessItemTypes.EVENT, setFieldValue)}>
                                        <SoftAvatar src={sportEventOption} size="xxl" variant="rounded"
                                            style={selectedTypeValue == BusinessItemTypes.EVENT ? styles.selectedImageOption : null}
                                        />
                                    </SoftBox>
                                    <SoftTypography
                                        component="label"
                                        variant="caption"
                                        fontWeight="bold"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => setSelectedValue(values, BusinessItemTypes.EVENT, setFieldValue)}
                                        style={selectedTypeValue == BusinessItemTypes.EVENT ? styles.selectedTextOption : null}
                                    >
                                        {t('createBusiness.steps.event')}
                                    </SoftTypography>
                                </Grid>
                            </Grid>
                        </SoftBox>
                        <ButtonsSection activeStep={activeStep} isLastStep={isLastStep} handleBack={handleBack} isSubmitting={isSubmitting} t={t} />
                    </SoftBox>
                </Form>
            )}
        </Formik>
    );
}

export default ChooseType;
