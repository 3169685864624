/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Footer from "layouts/pages/home/components/Footer";
import Faq from "layouts/pages/home/components/Faq";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import PublicHeader from "./components/PublicHeader";
import about_us_image from "assets/images/moveon/about_us_image.jpg";
import { useTranslation } from "react-i18next";
import typography from "assets/theme/base/typography";

function AboutUs() {
  const { t, i18n } = useTranslation();
  return (
    <PageLayout>
      <SoftBox xs={12} md={12} lg={12} align="center">
        <PublicHeader />
      </SoftBox>  

      <SoftBox mt={2} xs={12} md={12} lg={12} align="center">
            <SoftTypography variant="h2"  style={{ padding: "30px 30px 30px 30px" }}>
                    {t('aboutUs.header')}
            </SoftTypography>
      </SoftBox>
      <SoftBox xs={12} md={12} lg={12} align="left" width="100%">
      <Grid item xs={12} md={12} lg={12} padding="40px">
            <Grid item xs={12} md={6} lg={10} >
                <Card>
                    <SoftBox mt={2} xs={12} md={6} lg={6} style={{ float:"left" }}>
                        <Grid style={{ mx: "auto", padding: "30px 30px 30px 30px", backgroundColor: "#FFFFFF", float:"left" }} textAlign="justify">
                        <img src={about_us_image}
                            alt="Move On"
                            width="40%"
                            style={{ margin: "0 30px 30px 0", float:"left"}}
                          />
                          <p>
                            <div className="post__content" style={typography.body2}>
                                <div
                                    dangerouslySetInnerHTML={{ __html: t('aboutUs.text') }}
                                />
                                <style>
                                    {`
                                    .post__content ul, .post__content ol {
                                        margin-left: 20px;
                                        padding-left: 20px;
                                    }
                                    `}
                                </style>
                            </div>
                          </p>
                        </Grid>
                    </SoftBox>
                </Card>
            </Grid>
        </Grid>
      </SoftBox>
      <Container>
        <Faq />
      </Container>
      <SoftBox mt={8}>
        <Footer />
      </SoftBox>
    </PageLayout>
  );
}

export default AboutUs;
