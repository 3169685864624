import { Box, Modal, Rating } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Formik, Form } from "formik";

// Form schemas
import FormField from "components/common/FormField";
import SoftButton from "components/SoftButton";
import BackRequests from "helpers/back-request/back-requests.enum";
import { CreatePostRequest } from "helpers/back-request/helpers";
import { GetErrorMessage } from "helpers/back-request/helpers";
import { useTranslation } from "react-i18next";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { formFields } from "./schemas/form";
import { initialValues } from "./schemas/initialValues";
import { validations } from "./schemas/validations";

function ReviewModal({ handleClose, open, openErrorSB, openSuccessSB, businessItemId }) {
  const { t, i18n } = useTranslation();
  const formId = "write-review-comment";

  const handleSubmit = async (values, actions) => {
    console.log("handleSubmit")
    console.log(values)
    try {
      await CreatePostRequest(BackRequests.ADD_REVIEW,
        {
          userId: JSON.parse(localStorage.getItem("userId")),
          businessItemId,
          rating: values.rating,
          goodMessage: values.goodMessage,
          badMessage: values.badMessage,
        });
      handleClose()
      openSuccessSB("Review successfully added");
    } catch (error) {
      handleClose()
      openErrorSB(GetErrorMessage(error));
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 1,
    borderRadius: '30px',
    "@media (max-width: 430px)": {
      width: '80%',
    },
    "@media (min-width: 431px)": {
      width: '40%',
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <SoftBox pt={2} pb={3} px={3}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form id={formId} autoComplete="off">
                <SoftBox xs={12} md={12} lg={12}>
                  <SoftBox mb={1} textAlign="center">
                    <SoftTypography variant="h5" fontWeight="medium" mb={2}>
                      {formFields.rating.label}
                    </SoftTypography>
                    <Rating
                      name="simple-controlled"
                      value={values.rating}
                      size="large"
                      onChange={(event, newValue) => {
                        setFieldValue(formFields.rating.name, newValue);
                      }}
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftBox display="flex" alignItems="center" mb={-3}>
                      <SentimentSatisfiedAltIcon sx={{ fontWeight: "bold", marginRight: 1 }} fontSize="medium" color="success" />
                      <SoftTypography
                        component="label"
                        variant="body2"
                      >
                        {formFields.goodMessage.label}
                      </SoftTypography>
                    </SoftBox>
                    <FormField
                      type={formFields.goodMessage.type}
                      label=""
                      name={formFields.goodMessage.name}
                      value={values.goodMessage}
                      placeholder={formFields.goodMessage.placeholder}
                      error={errors.goodMessage && touched.goodMessage}
                      multiline
                      rows={3}
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftBox display="flex" alignItems="center" mb={-3}>
                      <SentimentVeryDissatisfiedIcon sx={{ fontWeight: "bold", marginRight: 1 }} fontSize="medium" color="dark" />
                      <SoftTypography
                        component="label"
                        variant="body2"
                      >
                        {formFields.badMessage.label}
                      </SoftTypography>
                    </SoftBox>
                    <FormField
                      type={formFields.badMessage.type}
                      label=""
                      name={formFields.badMessage.name}
                      value={values.badMessage}
                      placeholder={formFields.badMessage.placeholder}
                      error={errors.badMessage && touched.badMessage}
                      multiline
                      rows={3}
                    />
                  </SoftBox>
                  <SoftBox align="center">
                    <SoftBox mt={4}>
                      <SoftButton variant="gradient" color="success" type="submit" disabled={isSubmitting}>
                        {t('reviews.submit')}
                      </SoftButton>
                    </SoftBox>
                    {errors[formFields.rating.name] &&
                      (
                        <SoftTypography mt={2} color="error" variant="subtitle2">
                          {errors[formFields.rating.name]}
                        </SoftTypography>
                      )}
                  </SoftBox>
                </SoftBox>
              </Form>
            )}
          </Formik>
        </SoftBox>
      </Box>
    </Modal>
  );
}

export default ReviewModal;