import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

function ButtonsSection({activeStep, isLastStep, handleBack, isSubmitting, t}) {
    return (
        <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
        {activeStep === 0 ? (
          <SoftBox />
        ) : (
          <SoftButton variant="gradient" color="secondary" onClick={handleBack}>
              {t('buttonLabels.back')}
          </SoftButton>
        )}
        <SoftButton
          variant="gradient"
          color="dark"
          disabled={isSubmitting}
          type="submit"
        >
          {isLastStep ? t('buttonLabels.send') : t('buttonLabels.next')}
        </SoftButton>
      </SoftBox>
    );
}

export default ButtonsSection;
