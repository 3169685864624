/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import * as Yup from "yup";
import formSchema from "layouts/pages/client/profile/schemas/form";
import { getPhoneNumberRegex } from "helpers/general/helpers";

const validationsSchema = (t) => {
    const { formFields } = formSchema(t);

    const {
        firstName, lastName, email, age, gender, phoneNumber, profileImage, currentPassword, newPassword, repeatPassword
    } = formFields;

    const validations = {
        "edit-client-profile-form": Yup.object().shape({
            [firstName.name]: Yup.string().required(firstName.errorMsg),
            [lastName.name]: Yup.string().required(lastName.errorMsg),
            [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
            [gender.name]: Yup.object().required(gender.errorMsg),
            [age.name]: Yup.number().required(age.errorMsg).positive().min(10, age.bounderyInvalidMsg), // TODO: Koji je min broj godina
            [phoneNumber.name]: Yup.string().matches(getPhoneNumberRegex(), phoneNumber.invalidMsg),
            [profileImage.name]: Yup.mixed(), // TODO: CHeck this
        }),
        "change-password-form": Yup.object().shape({
            [currentPassword.name]: Yup.string().required(currentPassword.errorMsg).min(5, currentPassword.invalidMsg),
            [newPassword.name]: Yup.string().required(newPassword.errorMsg).min(5, newPassword.invalidMsg),
            [repeatPassword.name]: Yup.string()
                .required(repeatPassword.errorMsg)
                .oneOf([Yup.ref("newPassword"), null], repeatPassword.invalidMsg),
        })
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    const preventPasteNegative = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = parseFloat(clipboardData.getData('text'));

        if (pastedData < 0) {
            e.preventDefault();
        }
    };

    return { validations, preventMinus, preventPasteNegative };
}

export default validationsSchema;
  
