/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import StarIcon from '@mui/icons-material/Star';
import PlaceIcon from '@mui/icons-material/Place';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { useTranslation } from "react-i18next";
import RoutesNames from "helpers/enums/routes-map.enum";
import { getDisplayBusinessItemType } from "helpers/enums/business-item.enum";
import { Divider, Tooltip } from "@mui/material";

import SoftButton from "components/SoftButton";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftBadge from "components/SoftBadge";

function BusinessInfoPreviewInCard({ title, type, addresses, sports, rating, ratingCount, maxDiscount, eventId, showDivider }) {
    const { t, i18n } = useTranslation();

    return (
        <SoftBox height="100%" display="flex" flexDirection="column">
            <SoftBox flex="1" my={2} pl={1.5}>
                <SoftBox lineHeight={0} textAlign="left" marginBottom={!showDivider ? "16px" : 0}>
                    <SoftTypography variant="h6" fontWeight="bold"
                        component={Link}
                        to={RoutesNames.EVENT} state={{ id: eventId }}>
                        {title}
                    </SoftTypography>
                    {type ? (
                        <SoftBox mt={0.3}>
                            <SoftTypography
                                variant="caption"
                                fontWeight="regular"
                                color="text"
                                textTransform="capitalize"
                            >
                                {getDisplayBusinessItemType(type, t)}
                            </SoftTypography>
                        </SoftBox>
                    ) : null}
                </SoftBox>
                {showDivider &&
                    <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
                }
                <SoftBox>
                    <SoftBox display="flex" alignItems="center">
                        <StarIcon color="secondary" fontSize="small" />
                        <SoftTypography variant="body2" textTransform="capitalize" ml={0.5}>
                            {rating > 0
                                ? `${Number(rating)}/5 (${ratingCount} ${ratingCount === 1 ? t('general.review.review') : t('general.review.reviews')})`
                                : `(0 ${t('general.review.reviews')})`}
                        </SoftTypography>
                    </SoftBox>
                    {addresses && addresses.length > 0 && (
                        <SoftBox display="flex" alignItems="center">
                            <PlaceIcon color="secondary" fontSize="small" />
                            <SoftTypography variant="body2" textTransform="capitalize" ml={0.5}>
                                {addresses[0].city}
                            </SoftTypography>
                            {
                                addresses.length > 1 && (
                                    <Tooltip title={(
                                        <SoftBox>
                                            <SoftTypography color="secondary" variant="caption" fontWeight="bold">All locations:</SoftTypography>
                                            <SoftBox>
                                                {addresses.map((address, index) => {
                                                    return (
                                                        <SoftBadgeDot key={"address-" + index} color="light" badgeContent={address.city} font={{ color: "light" }} py={0.3} />
                                                    )
                                                })}
                                            </SoftBox>
                                        </SoftBox>
                                    )} describeChild arrow={false} placement="bottom-start" PopperProps={{
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, -15],
                                                },
                                            },
                                        ],
                                    }}
                                        enterTouchDelay={0}
                                    >
                                        <SoftTypography variant="body2" ml={0.5}>
                                            {t('general.others')}
                                        </SoftTypography>
                                    </Tooltip>
                                )
                            }
                        </SoftBox>
                    )}
                </SoftBox>
                <SoftBox mt={1}>
                    {
                        sports && sports.length > 0 && (
                            <SoftBox display="flex" alignItems="center">
                                <SoftTypography variant="body2">
                                    Sports:
                                </SoftTypography>
                                <SoftBadge size="xs" style={{ margin: 0 }} variant="contained" color="secondary" badgeContent={sports[0]} />
                                {
                                    sports.length > 1 && (
                                        <Tooltip title={(
                                            <SoftBox>
                                                <SoftTypography color="secondary" variant="caption" fontWeight="bold">{t('general.allSports')}</SoftTypography>
                                                <SoftBox>
                                                    {sports.map((sport, index) => {
                                                        return (
                                                            <SoftBadgeDot key={"sport-" + index} color="light" badgeContent={sport} font={{ color: "light" }} py={0.3} />
                                                        )
                                                    })}
                                                </SoftBox>
                                            </SoftBox>
                                        )} describeChild arrow={false} placement="bottom-start" PopperProps={{
                                            modifiers: [
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: [0, -15],
                                                    },
                                                },
                                            ],
                                        }}
                                            enterTouchDelay={0}
                                        >
                                            <SoftTypography variant="body2" ml={0.5}>
                                                {t('general.others')}
                                            </SoftTypography>
                                        </Tooltip>
                                    )
                                }
                            </SoftBox>
                        )
                    }
                    <SoftBox display="flex" alignItems="center">
                        <SoftTypography variant="body2" mr={1}>{t('cardLabels.discount')} </SoftTypography>
                        <SoftTypography variant="body2" color="secondary" fontWeight="bold">{maxDiscount}%</SoftTypography>
                    </SoftBox>
                </SoftBox>
            </SoftBox>
            <SoftBox mt="auto" display="flex" justifyContent="end">
                <SoftButton color="primary" variant="text" size="small" component={Link} to={RoutesNames.EVENT} state={{ id: eventId }}>
                    {t('general.viewDetails')}
                </SoftButton>
            </SoftBox>
        </SoftBox>

    );
}

BusinessInfoPreviewInCard.propTypes = {
    title: PropTypes.string.isRequired,
    addresses: PropTypes.array,
    sports: PropTypes.array,
    rating: PropTypes.string.isRequired,
    maxDiscount: PropTypes.number.isRequired,
    isSponsored: PropTypes.bool,
};

export default BusinessInfoPreviewInCard;
