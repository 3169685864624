/**
 =========================================================
 * MoveOn - v0.0.1
 =========================================================

 * Product Page: https://www.moveon.fit
 * Copyright 2022 MoveOn Tim (https://www.moveon.fit)

 Coded by www.moveon.fit

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from "react";

import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { CreateGetRequest } from "helpers/back-request/helpers";
import BackRequests from "helpers/back-request/back-requests.enum";

import getFormFields from "layouts/pages/business/edit-business/schemas/form";
import { useTranslation } from "react-i18next";

function SportFacilityAdditionalEdit({ values, errors, touched, type, setFieldValue }) {
  const { t, i18n } = useTranslation();
  const [additionalOptions, setAdditionalOptions] = useState([]);
  const {formFields} = getFormFields(t);

  const getAdditionalOptions = async () => {
    try {
      // Get client info from database
      const response = await CreateGetRequest(BackRequests.GET_BUSINESS_ITEM_ADDITIONAL + type);
      return response.data.data;
    } catch (error) {
      console.log("error")
      console.log(error)
      // TODO
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setAdditionalOptions(await getAdditionalOptions());
    }
    if (type) {
      loadData();
    }
  }, []);

  return (
    <SoftBox p={3} display="grid">
      <SoftTypography variant="h5">{t('businessItem.editPage.additional')}</SoftTypography>
      <SoftBox mt={3}>
        {additionalOptions?.map((option) => (
          <SoftBox key={option.value + "-soft-box"}>
            <Checkbox
              name={formFields.additional.name}
              checked={values.additional.findIndex((value) => value.value == option.value) > -1}
              onChange={(event) => {
                console.log("event da")
                if (event.target.checked) {
                  console.log(option.value)
                  values.additional.push(option)
                } else {
                  const index = values.additional.findIndex((value) => value.value == option.value);
                  if (index > -1) {
                    values.additional.splice(index, 1);
                  }
                }
                setFieldValue(formFields.additional.name, values.additional)
              }}
            />
            <SoftTypography
              variant="button"
              fontWeight="regular"
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              {option.label}
            </SoftTypography>
          </SoftBox>
        ))}
      </SoftBox>
    </SoftBox>
  );
}

export default SportFacilityAdditionalEdit;
